import React, { useState } from 'react';
import styles from './avatar.module.scss';
import logo from '../ui/images/myAvatar (1).png'
import logo1 from '../ui/images/myAvatar (2).png'
import logo2 from '../ui/images/myAvatar (4).png'
import logo3 from '../ui/images/myAvatar.png'
import logo4 from '../ui/images/1.png'
import logo5 from '../ui/images/2.png'
import logo6 from '../ui/images/3.png'
import logo7 from '../ui/images/4.png'
import logo8 from '../ui/images/5.png'
import logo9 from '../ui/images/6.png'
import logo10 from '../ui/images/7.png'
import logo11 from '../ui/images/8.png'
import logo12 from '../ui/images/9.png'
import logo13 from '../ui/images/10.png'
import logo14 from '../ui/images/myAvatar (9).png'

const UserAvatar = ({selectedAvatarId,setSelectedAvatarId,onConfirm}:any) => {
  const userAvatars = [
    { id: 1, image:logo},
    { id: 2, image:logo1},
    { id: 3, image:logo2},
    { id: 4, image:logo3},
    { id: 5, image:logo4},
    { id: 6, image:logo5},
    { id: 7, image:logo6},
    { id: 8, image:logo7},
    { id: 9, image:logo8},
    { id: 10, image:logo9},
    { id: 11, image:logo10},
    { id: 12, image:logo11},
    { id: 13, image:logo12},
    { id: 14, image:logo13},
    { id: 15, image:logo14}
  ];
  

  const handleAvatarSelection = (id: number) => {
    setSelectedAvatarId(id);
  };


  return (
    <>
      <div className={styles.avatar_list_wrap}>
        {userAvatars.map((avatar) => (
          <div
            key={avatar.id}
            className={selectedAvatarId === avatar.id ? styles.selected : ''}
            onClick={() => handleAvatarSelection(avatar.id)}
          >
            <img src={avatar?.image} alt='avatar_list'/>
          </div>
        ))}
      </div>
      <div className={styles.btn_selec_wrap}>
        <button className={styles.selectavatarbtn} 
        onClick={onConfirm}
        >Choose Avatar</button>
      </div>
    </>
  );
};

export default UserAvatar;
