import Cookies from 'js-cookie';
import React from 'react';
import { Navigate } from 'react-router-dom'; 

interface ProtectedRouteProps {
    element: JSX.Element;
}

const ProtectedRoute = ({ element }: ProtectedRouteProps) => {
    const isAuthenticated = Cookies.get('accessToken');
    return isAuthenticated && isAuthenticated !== '' ? (
        <>{element}</>
    ) : (
        <Navigate to="/login" />
    );
};

export default ProtectedRoute;
