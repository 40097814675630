import React from 'react'

const LogoutIcon = () => {
  return (
    <svg width="30" height="28" viewBox="0 0 637 622" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M337.135 382.544C341.475 191.606 299.816 103.948 154.876 55.346C200.007 44.9431 286.45 48.735 337.135 60.5384C387.821 72.3418 417.85 108.273 421.321 192.474C424.793 224.571 468.188 222.835 468.188 192.474C468.188 77.8971 416.114 -30.5842 154.876 9.34734C22.088 21.4979 -7.42073 110.024 2.12636 314.848C9.56178 474.368 36.8423 571.128 214.761 616.008C311.098 640.31 340.607 583.896 337.135 382.544Z" fill="#111"/>
<path d="M333.666 501.432C367.514 493.62 421.324 464.112 421.324 367.775C428.267 331.32 469.926 341.729 468.191 367.775C475.134 498.819 373.069 552.108 325.855 550.025L277.253 515.309L333.666 501.432Z" fill="#111"/>
<path d="M395.287 256.675H555.848L507.246 205.456C496.831 192.958 516.793 155.115 546.301 179.419L627.016 260.133C638.125 269.854 640.035 285.302 627.016 299.189L546.301 379.903C522.868 404.204 490.756 372.96 509.85 348.659L555.848 303.542H395.287C359.703 302.66 358.835 256.662 395.287 256.675Z" fill="#111"/>
</svg>
  )
}

export default LogoutIcon
