import React from 'react'

const Casino = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.0153 14.7851C25.1409 14.7851 25.2429 14.6833 25.2402 14.5577C25.187 12.1121 24.2778 9.76225 22.671 7.91781C22.5886 7.82312 22.4445 7.8165 22.3517 7.90103L20.7549 9.35421C20.6621 9.43874 20.6556 9.58236 20.7374 9.67765C21.907 11.04 22.5736 12.7629 22.6256 14.5577C22.6293 14.6832 22.7307 14.7851 22.8563 14.7851H25.0153Z" fill="black"/>
<path d="M22.0178 7.55508C22.1066 7.46628 22.1067 7.32211 22.016 7.23526C20.2492 5.54356 17.9446 4.52487 15.5042 4.35681C15.379 4.34818 15.2724 4.44534 15.2665 4.57078L15.165 6.72739C15.1591 6.85283 15.2561 6.95896 15.3813 6.9685C17.1717 7.10484 18.8613 7.85173 20.1672 9.08404C20.2586 9.17023 20.4023 9.17052 20.4911 9.08172L22.0178 7.55508Z" fill="black"/>
<path d="M14.7851 4.55759C14.7851 4.43201 14.6833 4.32995 14.5577 4.33267C12.1121 4.38583 9.76225 5.29506 7.91781 6.90183C7.82312 6.98432 7.8165 7.12834 7.90103 7.22122L9.35421 8.81793C9.43874 8.9108 9.58236 8.91728 9.67765 8.83548C11.04 7.66592 12.7629 6.99928 14.5577 6.94725C14.6832 6.94361 14.7851 6.84216 14.7851 6.71658V4.55759Z" fill="black"/>
<path d="M7.55459 7.55461C7.46579 7.46581 7.32162 7.46565 7.23477 7.55636C5.54308 9.32323 4.52438 11.6278 4.35632 14.0681C4.34769 14.1934 4.44485 14.2999 4.5703 14.3059L6.7269 14.4073C6.85234 14.4132 6.95847 14.3163 6.96801 14.191C7.10435 12.4007 7.85124 10.711 9.08355 9.40515C9.16974 9.31381 9.17003 9.17005 9.08123 9.08125L7.55459 7.55461Z" fill="black"/>
<path d="M4.55758 14.7872C4.432 14.7872 4.32994 14.889 4.33267 15.0146C4.38583 17.4601 5.29506 19.81 6.90183 21.6545C6.98431 21.7491 7.12834 21.7558 7.22121 21.6712L8.81793 20.2181C8.9108 20.1335 8.91727 19.9899 8.83548 19.8946C7.66592 18.5322 6.99928 16.8093 6.94725 15.0146C6.94361 14.889 6.84215 14.7872 6.71657 14.7872L4.55758 14.7872Z" fill="black"/>
<path d="M7.55583 22.0191C7.46703 22.1079 7.46687 22.2521 7.55758 22.339C9.32444 24.0307 11.629 25.0494 14.0694 25.2174C14.1946 25.226 14.3012 25.1289 14.3071 25.0034L14.4086 22.8468C14.4145 22.7214 14.3175 22.6153 14.1923 22.6057C12.4019 22.4694 10.7123 21.7225 9.40637 20.4902C9.31503 20.404 9.17126 20.4037 9.08246 20.4925L7.55583 22.0191Z" fill="black"/>
<path d="M14.7884 25.0147C14.7884 25.1403 14.8902 25.2423 15.0158 25.2396C17.4613 25.1864 19.8112 24.2772 21.6557 22.6704C21.7504 22.588 21.757 22.4439 21.6725 22.3511L20.2193 20.7543C20.1347 20.6615 19.9911 20.655 19.8958 20.7368C18.5335 21.9064 16.8106 22.573 15.0158 22.625C14.8903 22.6287 14.7884 22.7301 14.7884 22.8557V25.0147Z" fill="black"/>
<path d="M22.0182 22.0177C22.107 22.1065 22.2511 22.1066 22.338 22.0159C24.0297 20.249 25.0484 17.9445 25.2164 15.5041C25.2251 15.3788 25.1279 15.2723 25.0025 15.2664L22.8459 15.1649C22.7204 15.159 22.6143 15.256 22.6047 15.3812C22.4684 17.1716 21.7215 18.8612 20.4892 20.1671C20.403 20.2585 20.4027 20.4022 20.4915 20.491L22.0182 22.0177Z" fill="black"/>
<path d="M14.3753 13.123L12.5375 14.7464L10.8427 13.123L10.5364 13.4089L11.537 16.2575H18.0509L19.0107 13.4089L18.7044 13.123L17.0095 14.7464L15.1513 13.123H14.3753Z" fill="black"/>
<path d="M18.0509 16.6455H11.537C11.537 17.1968 11.9658 17.8911 12.6601 17.8911H16.8666C17.4587 17.8911 18.0509 17.3602 18.0509 16.6455Z" fill="black"/>
<circle cx="10.31" cy="12.7225" r="0.714698" fill="black"/>
<circle cx="14.7616" cy="12.4354" r="0.714698" fill="black"/>
<circle cx="19.2962" cy="12.7225" r="0.714698" fill="black"/>
</svg>
  )
}

export default Casino
