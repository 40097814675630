import React, { useState } from 'react';
import styles from "./signup.module.scss";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login, signUp } from '../../api/api';
import UserIcon from '../ui/images/UserIcon';
import LockIcon from '../ui/images/LockIcon';
import EyeIcon from '../ui/images/EyeIcon';
import OpenEye from '../ui/images/OpenEye';
import Logo from '../ui/images/Logo';
import MobileIcon from '../ui/images/MobileIcon';

export interface LoginData {
    mobile: string;
    countryCode: string;
    type: string,
    ChannalType: string
}

const SignUp = () => {
    const [hide, setHide] = useState(true)
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState<LoginData>({
        mobile: '',
        countryCode: "91",
        type: "SMS",
        ChannalType: "ACCOUNT_VERIFICATION"
    });

    const [errorMsg, setErrorMsg] = useState({
        mobilevalidnumber: false,
        registerErr:null
    });

    const [errMsgres, setErrMsgres] = useState("")

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
    
        setErrorMsg({
            mobilevalidnumber: false,
            registerErr: null
        });
    
        setErrMsgres("");
    
        let hasError = false;
    
        // Validate mobile number
        if (loginData.mobile.length !== 10) {
            setErrorMsg((prev) => ({ ...prev, mobilevalidnumber: true }));
            hasError = true;
        }
    
        // Check if user is already registered
        const isRegistered = localStorage.getItem("isRegistered") === "false";
    
        if (isRegistered) {
            setErrorMsg((prev:any) => ({ ...prev, registerErr: true }));
            hasError = true;
        }
    
        if (hasError) return;
    
        try {
            const res = await signUp(loginData);
    
            if (res.status === 200) {
                setErrMsgres('');
                Cookies.set('mobile', loginData?.mobile);
                Cookies.set("OtpAccessToken", res?.data?.data?.token);
                navigate(`/otp`, { replace: true });
            } else if (res.status === 400) {
                setErrMsgres(res.data.message);
            } else {
                console.log("Sign-up unsuccessful");
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "An error occurred during sign-up";
            setErrMsgres(errorMessage);
    
            setErrorMsg({
                mobilevalidnumber: false,
                registerErr: null
            });
        }
    };
    

    const onHandleRoute = (route: any) => {
        navigate(route);
    }

    return (
        <form className={styles.login_main_wrap} onSubmit={handleSubmit}>
            <div className={styles.logn_sub}>
                <div>
                    <Logo />
                </div>
                <h1 className={styles.login}>Sign Up</h1>
                <section className={styles.input_section}>
                    <div className={styles.mobilenumber}>
                        <span><MobileIcon /></span>
                        <input
                            type='text'
                            name="mobile"
                            className={styles.logInp}
                            id="mobile"
                            placeholder='Mobile number'
                            value={loginData.mobile}
                            onChange={handleInputChange}
                        />
                    </div>
                    {errorMsg.mobilevalidnumber && <span className={styles.errormsg}>Please enter a valid number</span>}
                    {errMsgres && <span className={styles.errormsg}>{errMsgres}</span>}
                    {errorMsg.registerErr && <span className={styles.errormsg}>User already Existed</span>}
                </section>
                <div className={styles.fp_cont}>
                    <p className={styles.fp_lf}><input className={styles.fp_checkBox} type="checkbox" /><span className={styles.fpText1}>Remember password</span></p>
                </div>
            </div>

            <section className={styles.btn_section}>
                <p className={styles.terms} onClick={() => onHandleRoute("/privacypolicy")}>T&C & Privacy Policy</p>
                <button className={styles.lg_btn}>Sign Up</button>
                <div className={styles.a_cont}>
                    <p className={styles.aText}>Already an existing user? <span className={styles.signIn} onClick={() => onHandleRoute("/login")}>Log in</span></p>
                </div>
            </section>
        </form>
    );
}

export default SignUp;
