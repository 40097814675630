import React, { useState } from 'react';
import styles from "./forgot.module.scss";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { forgotrequestOtp } from '../../api/api';
import Logo from '../ui/images/Logo';
import MobileIcon from '../ui/images/MobileIcon';

export interface LoginData {
    mobile: string;
    countryCode: string;
    type: string,
    ChannalType: string
}

const ForgotPassword = () => {
    const [hide, setHide] = useState(true)
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState<LoginData>({
        mobile: '',
        countryCode: "91",
        type: "SMS",
        ChannalType: "ACCOUNT_VERIFICATION"
    });

    const [errorMsg, setErrorMsg] = useState({
        mobilevalidnumber: false
    });

    const [errMsgres, setErrMsgres] = useState("")

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setErrorMsg({
            mobilevalidnumber: false
        });

        let hasError = false;

        if (loginData.mobile.length < 10 || loginData.mobile.length > 10) {
            setErrorMsg((prev) => ({ ...prev, mobilevalidnumber: true }));
            hasError = true;
        }

        if (hasError) return;

        try {
            const res = await forgotrequestOtp(loginData); 
            if (res.status === 200) {
                setErrMsgres('')
                navigate('/passwordreset',{ replace: true });
            } else if (res.status === 400) {
                setErrMsgres(res.data.message);
            } else {
                console.log("Login unsuccessful");
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "An error occurred during login";
            setErrMsgres(errorMessage);
            if (errorMessage) {
                setErrorMsg({
                    mobilevalidnumber: false
                })
            }
        }
    };

    const onHandlePrivacyPolicy = (route: any) => {
        navigate(route);
    }

    return (
        <form className={styles.login_main_wrap} onSubmit={handleSubmit}>
            <div className={styles.logn_sub}>
                <div>
                    <Logo />
                </div>
                <h1 className={styles.login}>Forgot Password</h1>
                <section className={styles.input_section}>
                    <div className={styles.mobilenumber}>
                        <span><MobileIcon /></span>
                        <input
                            type='text'
                            name="mobile"
                            className={styles.logInp}
                            id="mobile"
                            placeholder='Mobile number'
                            value={loginData.mobile}
                            onChange={handleInputChange}
                        />
                    </div>
                    {errorMsg.mobilevalidnumber && <span className={styles.errormsg}>Please enter a valid number</span>}
                    {errMsgres && <span className={styles.errormsg}>{errMsgres}</span>}
                </section>
            </div>

            <section className={styles.btn_section}>
                <p className={styles.terms} onClick={onHandlePrivacyPolicy}>T&C & Privacy Policy</p>
                <button className={styles.lg_btn}>Submit</button>
                <div className={styles.a_cont}>
                    <p className={styles.aText}>Already an existing user? <span className={styles.signIn} onClick={() => onHandlePrivacyPolicy("/login")}>Log in</span></p>
                </div>
            </section>
        </form>
    );
}

export default ForgotPassword;
