import React, { useEffect, useState } from 'react'
import styles from './mybet.module.scss'
import DepositeIcon from '../ui/images/DepositeIcon'
import { alluserbet } from '../../api/api'
import Cookies from 'js-cookie'
import Navbar from '../navbar/Navbar'
import Loading from '../ui/loading/Loading'
import PaginationComp from '../pagination/PaginationComp'
import HistoryIcon from '../ui/images/HistoryIcon'
import NoResult from '../ui/images/NoResult'
import { Helmet } from 'react-helmet'

const MyBet = () => {
  const [loading, setLoading] = useState(true);
  const [depositedata, setDepositeData] = useState([])
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchAllMybet = async (page: any) => {
    try {
      const res = await alluserbet({
        pageNumber: page-1,
        itemPerPage: 15,
      })
      if (res?.status === 200) {
        setLoading(false)
        setDepositeData(res?.data?.data?.data);
        const totalRecords = (Math.ceil((res?.data?.data?.totalRecords / 15))) ?? 0
        setTotalPages(totalRecords);
      }

    } catch (error) {
      console.log(error, "error");

    }
  }

  useEffect(() => {
    fetchAllMybet(pageNumber);
  }, [pageNumber])


  return (
    <>
      <Helmet>
    <title>{"Rapid Games My Bet"}</title>
    <meta name="description" content="Keep track of your betting history on Rapid Games. View detailed records of past bets, winnings, and outcomes. Analyze your performance and manage your gaming strategy with our secure and user-friendly platform. Stay informed and in control of your bets today!" />
    <meta name="keywords" content="my bets, betting history, bet records, gaming performance, track bets, past bets, winning history, secure betting platform, Rapid Games bets, gaming strategy, bet tracking, bet outcomes, gaming history" />

  </Helmet>

      <main className={styles.deposite_box_main_wrap}>
        <div className={styles.nav_GCont}>
          <Navbar name={"My Bet"} />
        </div>
        {loading ? <Loading /> :
          (depositedata?.length === 0 ? <div className={styles.noresult_wrap}>
            <NoResult />
            <p className={styles.noresult}>No Withdraw Found</p>
          </div> : (
            depositedata?.map((item: any) => {
              const updatedDate = new Date(item.updatedAt);
              const date = updatedDate.toLocaleDateString('en-IN');
              const time = updatedDate.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' });
              return <section className={styles.deposite_box}>
                <div className={styles.deposite_left_side}>
                  <p>Bet Amount</p>
                  <p>PayOut</p>
                  <p>Transaction ID</p>
                  <p>Date</p>
                </div>

                <div className={styles.deposite_right_side}>
                  <p>₹ {item?.payIn / 100}</p>
                  <p>₹ {item?.payOut / 100}</p>
                  <p className={styles.transId}>{item?.transactionId}</p>
                  <p>{date} <span>{time}</span></p>
                </div>
              </section>
            })
          )
          )}
        {
          depositedata.length !== 0 ?
            <PaginationComp
              totalPages={totalPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber} /> : ""
        }
      </main>
    </>
  )
}

export default MyBet

