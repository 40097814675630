import React from 'react';
import styles from "./splashScreen.module.scss"

const SplashScreen = () => {
  return (
    <div>
      <video src="/CasinoIntro.mp4"
      playsInline 
      autoPlay 
      preload="auto"
      loop
      muted
      className={styles.splashScr}
      />
      
    </div>
  );
};

export default SplashScreen;
