import React from 'react'

const UserNav = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 265 270" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M186.51 131.811C154.258 156.791 113.012 158.12 78.2788 131.811C74.7278 129.26 65.2212 129.508 60.9118 129.951C20.4724 134.416 4.98754 173.799 2.30001 192.933C-3.40615 219.933 -2.35223 270 51.9188 270H209.769C234.33 269.575 274.893 261.762 262.799 192.933C260.07 166.996 243.571 137.159 205.117 129.951C197.178 128.463 189.921 129.169 186.51 131.811Z" fill="#111" />
            <ellipse cx="132.42" cy="63.5138" rx="74.1181" ry="63.5138" fill="#111" />
        </svg>
    )
}

export default UserNav
