import React, { useState } from 'react'
import { addBank } from '../../api/api';
import { toast } from 'react-toastify';
import styles from "./addBankAcc.module.scss"
import Navbar from '../navbar/Navbar';
import { useNavigate } from 'react-router-dom';

export interface BankDetails {
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  accountType: string;
}
const AddBankAcc = () => {

  const navigate = useNavigate()
  const [bankData, setBankData] = useState<BankDetails>({
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountType: "BANK",
  });

  const updateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankData({ ...bankData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let body = { ...bankData };
    let res;
    try {
      if (
        !bankData.accountHolderName ||
        !bankData.accountNumber ||
        !bankData.ifscCode ||
        !bankData.bankName ||
        bankData.accountType === ""
      ) {
        toast.error("Please enter valid fields");
        return;
      }
      res = await addBank(body);
      if (res.status === 200) {
        toast.success("Bank added successfully");
        navigate("/withdraw");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };


  return (
    <div className={styles.addAcc_cont}>
      <h1 className={styles.nav_GCont}>
        <Navbar name={"Add Bank Account"} />
      </h1>
      <form onSubmit={handleSubmit} className={styles.addbank_form}>
        <p className={styles.caut_text}>Please complete bank details</p>
        <div className={styles.bankFields}>
          <input
            type="text"
            name="accountHolderName"
            className={styles.logInp}
            id="accountHolderName"
            placeholder="*Account Holder Name"
            value={bankData.accountHolderName}
            onChange={updateInputChange}
          />
        </div>
        <div className={styles.bankFields}>
          <input
            type="text"
            name="accountNumber"
            className={styles.logInp}
            id="accountNumber"
            placeholder="*Account Number"
            value={bankData.accountNumber}
            onChange={updateInputChange}
          />
        </div>
        <div className={styles.bankFields}>
          <input
            type="text"
            name="bankName"
            className={styles.logInp}
            id="bankName"
            placeholder="*Bank Name"
            value={bankData.bankName}
            onChange={updateInputChange}
          />
        </div>
        <div className={styles.bankFields}>
          <input
            type="text"
            name="ifscCode"
            className={styles.logInp}
            id="ifscCode"
            placeholder="*IFSC Code"
            value={bankData.ifscCode}
            onChange={updateInputChange}
          />
        </div>
        <section className={styles.pay_button_wrap}>
            <p className={styles.btn_info}>
              By completing, you confirm your acceptance of the Offer T&C and
              Bonus T&C
            </p>
            <button className={styles.pay_btn} onClick={handleSubmit}>
              Add Bank Account
            </button>
          </section>
      </form>
    </div>
  )
}

export default AddBankAcc
