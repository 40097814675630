import React from 'react'

const LockIcon = () => {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.81375 7.82483H12.0754M3.81375 7.82483V4.59202C3.81375 1.7184 6.90687 1 8.02439 1C9.42129 1 11.8758 2.13747 12.0754 4.59202V7.82483M3.81375 7.82483H2.63636C1.83814 7.82483 1 8.46341 1 9.80044V16.9446C1 18.122 1.898 19 2.63636 19H13.153C13.9512 19 14.945 18.3016 14.929 16.9446V9.80044C14.929 7.82483 13.4483 7.82483 12.0754 7.82483" stroke="black" stroke-width="1.19734"/>
<circle cx="7.98408" cy="12.4931" r="1.21729" fill="black" stroke="black" stroke-width="0.0399113"/>
<rect x="7.12591" y="12.6743" width="1.71619" height="2.87361" rx="0.658537" fill="black" stroke="black" stroke-width="0.0399113"/>
</svg>

    )
}

export default LockIcon
