import React, { useEffect, useState } from 'react';
import styles from './deposite.module.scss';
import DepositeIcon from '../ui/images/DepositeIcon';
import { depositeHistory } from '../../api/api';
import Cookies from 'js-cookie';
import Navbar from '../navbar/Navbar';
import Loading from '../ui/loading/Loading';
import PaginationComp from '../pagination/PaginationComp';
import HistoryIcon from '../ui/images/HistoryIcon';
import NoResult from '../ui/images/NoResult';
import { Helmet } from "react-helmet";
import { Modal } from 'antd';
import CalenderDatePicker from '../../datepicker/CalenderDatePicker';

const DepositeHistory = () => {
  const [loading, setLoading] = useState(true);
  const [depositedata, setDepositeData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const userId = parseInt(Cookies.get('userId') || '0');
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 2)));
  const [endDate, setEndDate] = useState(new Date());
  const [activeFilter, setActiveFilter] = useState<string | null>("ALL");
  const filters = [
    "ALL", "CONFIRMED", "FAILED", "DECLINED","CALENDER"
  ]
  const [status, setStatus] = useState("")
  const [modalOpen, setModalOpen] = useState(false);


  const fetchDepositeHistory = async (page: any) => {
    try {
      setLoading(true);
      const res = await depositeHistory({
        userId,
        paymentType: "DEPOSIT",
        pageNumber: page - 1,
        itemPerPage: 15,
        status: status,
        startDate: startDate,
        endDate: endDate
      });
      if (res?.status === 200) {
        setDepositeData(res?.data?.data?.data);
        const totalRecords = Math.ceil((res?.data?.data?.totalRecords / 15)) ?? 0
        setTotalPages(totalRecords);
      }
      setLoading(false);
    } catch (error) {
      console.error(error, 'Error fetching deposit history');
      setLoading(false);
    }
  };

  const handleFilterClick = (fname: string) => {
    if (fname === "CONFIRMED" || "FAILED" || "DECLINED") {
      setPageNumber(1)
    }
    const ffname = fname === "ALL" || "CALENDER" ? "" : fname
    setStatus(ffname)
    setActiveFilter(fname);
    if (fname === "ALL") {
      setStatus("")
    } else if (fname === "CALENDER") {
      setModalOpen(true)
    }
  }

  useEffect(() => {
    fetchDepositeHistory(pageNumber);
  }, [pageNumber, status]);

  const handleDateChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleSearchClick = () => {
    fetchDepositeHistory(1);
    setModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{"Rapid Games Deposite History"}</title>
        <meta name="description" content="Track your deposit history with ease. View detailed records of your payments, withdrawals, and account activity in one secure place. Our user-friendly interface ensures quick access to past transactions, helping you manage your gaming finances effortlessly. Stay informed and in control with our transparent transaction history page." />
        <meta name="keywords" content="deposit history, payment records, account transactions, gaming finance tracker, payment tracking, withdrawal history, account activity, financial overview, transaction management, deposit tracking system" />
      </Helmet>
      <main className={styles.deposite_box_main_wrap}>
        <div className={styles.nav_GCont}>
          <Navbar name="Deposit Transaction" />
        </div>
        <article className={styles.filters}>
          {filters?.map((fname: string, i: any) => {
            return (
              <div className={`${activeFilter === fname ? styles.filter_field_active : styles.filter_field}`} onClick={() => handleFilterClick(fname)} key={i}>{fname}</div>
            )
          })}
        </article>

        <Modal
          centered
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
        >
          <div className={styles.calender}>
            <CalenderDatePicker
              startDate={startDate}
              endDate={endDate}
              fetchAllTransaction={fetchDepositeHistory}
              onDateChange={handleDateChange}
              onSearch={handleSearchClick}
            />
          </div>
        </Modal>
        {loading ? (
          <Loading />
        ) : depositedata.length === 0 ? (
          <div className={styles.noresult_wrap}>
            <NoResult />
            <p className={styles.noresult}>No Withdraw Found</p>
          </div>
        ) :
          (
            depositedata.map((item: any) => {

              const updatedDate = new Date(item.updatedDate);
              const date = updatedDate.toLocaleDateString('en-IN');
              const time = updatedDate.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' });

              return (
                <section key={item.transactionId} className={styles.deposite_box}>
                  <div className={styles.deposite_left_side}>
                    <div className={styles.name_wrap}>
                      <HistoryIcon />
                      <h2 className={styles.payt_name}>
                        {item?.paymentGateway}</h2>
                    </div>
                    <p>Deposit Amount</p>
                    <p>Transaction ID</p>
                    <p>{date} <span>{time}</span></p>
                  </div>

                  <div className={styles.deposite_right_side}>
                    <h2 className={styles.amt}>₹ {item?.amount / 100}</h2>
                    <p>₹ {item?.amount / 100}</p>
                    <p className={styles.transId}>{item?.transactionId}</p>
                    <p className={`${item.status === "FAILED" ? styles.failed : item.status === "CONFIRMED" ? styles.confirm : item.status === "DECLINED" ?
                      styles.declined : ""
                      }`}>{item?.status}</p>
                  </div>
                </section>
              );
            })
          )}

        {
          depositedata.length !== 0 &&
          <PaginationComp
            totalPages={totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber} />
        }


      </main>
    </>
  );
};

export default DepositeHistory;
