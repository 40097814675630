import React from 'react'
import styles from './withdrawdetails.module.scss'
import Navbar from '../navbar/Navbar'
import { useSelector } from '../../redux/store'


const WithdrawDetails = () => {

    const withdrawDetails = useSelector((state) => state.withdrawReducer)

    return (
        <>
            <div>
                <h1>
                    <Navbar name={"Withdraw"} />
                </h1>
                <main className={styles.withdrawdetails_main_wrap}>
                    <section className={styles.withdrawdetails_box}>
                        <h2 className={styles.details_heading}>Withdrawl Details</h2>
                        <div className={styles.withdrawbox_details}>
                            <div className={styles.withdraw_left_section}>
                                <p>Bank Name</p>
                                <p>Withdraw Amount</p>
                                <p>Processing fee</p>
                                <p>All charges</p>
                            </div>

                            <div className={styles.withdraw_right_section}>
                                <p>{withdrawDetails?.withdrawDetails?.bankName}</p>
                                <p>{withdrawDetails?.withdrawDetails.amount}</p>
                                <p>₹50</p>
                                <p>₹5</p>
                            </div>
                        </div>
                        <div className={styles.border_btm}></div>
                        <div className={styles.withdraw_btm_sec}>
                            <p>Total Withdraw Amount</p>
                            <p>₹555</p>
                        </div>
                    </section>

                    <section className={styles.pay_button_wrap}>
                        <p className={styles.btn_info}>By completing, you confirm your acceptance of the Offer T&C and Bonus T&C</p>
                        <button className={styles.pay_btn}>Confirm</button>
                    </section>
                </main>
            </div>
        </>
    )
}

export default WithdrawDetails
