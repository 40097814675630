import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import styles from "./iframe.module.scss"
import Loading from '../ui/loading/Loading';
import loadingsvg from "../ui/images/1167 (1).gif"

const Iframe = () => {

  const location = useLocation();
  const [gameId, setGameId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Add state for loading

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    
    const gameIdParam = params.get('gameId') 
    setGameId(gameIdParam);
    console.log("Params object:", params.toString());
    console.log("Extracted gameId:", gameIdParam);  }, [location.search]);

    console.log("sdfsdfs",gameId)

    const handleIframeLoad = () => {
      setLoading(false); // Hide loading spinner once iframe is loaded
    };

  return (
    <div className={` ${styles.iframe_cont}`}>
      {loading && <div className={styles?.loading_cont}><img src={loadingsvg} alt='loading'/>
        </div>}
       <iframe
          src={gameId ?? undefined}
          title="Game"
          onLoad={handleIframeLoad}
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
          }}
        />
    </div>
  )
}

export default Iframe
