import React from 'react'

const Phone = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.65625 7.42672C8.76536 8.30344 8.26007 8.62661 7.82204 9.00545C7.67579 9.08589 7.38878 9.34053 7.53685 9.66358C9.22605 13.3491 11.4198 14.5118 13.4161 15.4771C13.7233 15.6256 13.8549 15.2138 14.2278 14.8189C14.9737 14.0292 15.8951 14.2705 16.5094 14.4021L20.6117 15.0822C21.1748 15.3308 22.0759 15.6087 21.8841 17.6708C21.6923 19.733 20.4362 21.3564 18.1986 21.9048C12.2968 22.7823 1 17.627 1 5.05642C1 3.15986 3.4351 0.755637 5.8921 1.02005C6.94511 1.13338 7.5813 1.54647 7.7568 2.16168L8.65625 7.42672Z" stroke="black" stroke-width="1.7"/>
</svg>

  )
}

export default Phone
