import React from 'react'
import styles from './pagination.module.scss'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationComp = ({ totalPages, pageNumber, setPageNumber }: any) => {

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: any) => {
    setPageNumber(page);
  };
  return (
    <>
      <div className={styles.pagination}>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={pageNumber}
            size="small"
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </>
  )
}

export default PaginationComp
