import React from 'react'

const MobileIcon = () => {
    return (
        <>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.05615 7.00862C8.14967 7.76009 7.71657 8.0371 7.34111 8.36181C7.21575 8.43076 6.96975 8.64903 7.09666 8.92593C8.54455 12.0849 10.4249 13.0815 12.1361 13.9089C12.3993 14.0362 12.5121 13.6833 12.8318 13.3448C13.4711 12.6679 14.2609 12.8747 14.7874 12.9875L18.3037 13.5704C18.7863 13.7836 19.5587 14.0217 19.3943 15.7893C19.2299 17.5568 18.1532 18.9483 16.2353 19.4184C11.1766 20.1705 1.49366 15.7517 1.49365 4.97693C1.49365 3.35131 3.58088 1.29055 5.68688 1.51719C6.58946 1.61432 7.13477 1.96841 7.2852 2.49573L8.05615 7.00862Z" stroke="black" stroke-width="1.2" />
            </svg>

        </>
    )
}

export default MobileIcon
