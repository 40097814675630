import React, { useState, useEffect, Suspense,lazy } from 'react';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/mainLayout/Layout';
import Login from './components/login/Login';
import Games from './components/games/Games';
import Iframe from './components/iframe/Iframe';
import UserProfile from './components/userprofile/UserProfile';
import ProtectedRoute from './ProductedRoute';
import DepositeHistory from './components/depositehistory/DepositeHistory';
import WithDrawHistory from './components/withdrawhistory/WithDrawHistory';
import Deposit from './components/deposit/Deposit';
import BottomNavbar from './components/BottomNavbar/BottomNavbar';
import MyTransaction from './components/mytransaction/MyTransaction';
import MyBet from './components/mybet/MyBet';
import Cookies from 'js-cookie';
import TermsandCondition from './components/termscondition/TermsandCondition';
import SignUp from './components/signup/SignUp';
import Otp from './components/otp/Otp';
import SplashScreen from './components/splashscreen/SplashScreen';
import SetPassword from './components/setPassword/SetPassword';
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import PasswordReset from './components/passwordReset/PasswordReset';
import EditUserProfile from './components/editUserProfile/EditUserProfile';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PageNotFound from './components/pagenotfound/PageNotFound';
import Withdraw from './components/withdraw/Withdraw';
import WithdrawDetails from './components/withdrawDetails/WithdrawDetails';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './components/ui/loading/Loading';
import { Slide, ToastContainer } from 'react-toastify';
import AddBankAcc from './components/addBankAcc/AddBankAcc';

const Promotions = lazy(() => import('./components/promotions/Promotions'));

function App() {
  
  const isAuthenticated = !!Cookies.get('accessToken');
  const location = useLocation();
  const hideNavbarRoutes = ['/login', '/lobby', '/signup', '/otp', '/setpassword', '/forgotpassword', '/passwordreset', '/deposite', '/withdraw', '/withdrawdetails','/addbank','/privacypolicy'];
  const shouldShowNavbar = !hideNavbarRoutes.includes(location.pathname);

  const [showSplashScreen, setShowSplashScreen] = useState(false);

  useEffect(() => {
    if (location.pathname === '/login') {
      setShowSplashScreen(true);
      const timer = setTimeout(() => {
        setShowSplashScreen(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  if (showSplashScreen && location.pathname === '/login') {
    return <div style={{ width: "100%", display: "flex", justifyContent: "center" }}> <SplashScreen /> </div>;
  }

  const isMobile = window.innerWidth <= 400;
  const toastPosition = isMobile ? 'top-center' : 'top-center';

  return (
    <>
     <ToastContainer autoClose={1000} hideProgressBar={false} closeButton={false} style={{width:'300px'}} position={toastPosition} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className='layout_main_wrap'>
          <Routes>
            <Route
              path="/login"
              element={
                isAuthenticated && !location?.state?.fromReset
                  ? <Navigate to="/" replace />
                  : <Login />
              }
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/" element={<ProtectedRoute element={<Games />} />} />
            <Route path="/lobby" element={<Iframe />} />
            <Route path="/userprofile" element={<ProtectedRoute element={<UserProfile />} />} />
            <Route path="/depositehistory" element={<ProtectedRoute element={<DepositeHistory />} />} />
            <Route path="/withdrawhistory" element={<ProtectedRoute element={<WithDrawHistory />} />} />
            <Route path="/deposite" element={<ProtectedRoute element={<Deposit />} />} />
            <Route path="/alltransaction" element={<ProtectedRoute element={<MyTransaction />} />} />
            <Route path="/mybet" element={<ProtectedRoute element={<MyBet />} />} />
            <Route
              path="/promotions"
              element={
                <Suspense fallback={<Loading/>}><Promotions /></Suspense>} 
            />
            <Route path="/privacypolicy" element={<TermsandCondition />} />
            <Route path="/setpassword" element={<SetPassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route path='/editUser' element={<ProtectedRoute element={<EditUserProfile />} />} />
            <Route path='/withdraw' element={<ProtectedRoute element={<Withdraw />} />} />
            <Route path='/withdrawdetails' element={<ProtectedRoute element={<WithdrawDetails />} />} />
            <Route path='/addbank' element={<ProtectedRoute element={<AddBankAcc />} />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          {shouldShowNavbar && <BottomNavbar />}
        </div>
      </LocalizationProvider>
    </>
  );
}

export default App;
