import React, { useState } from 'react';
import styles from "./setpassword.module.scss";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login, setUserPassword } from '../../api/api';
import Logo from '../ui/images/Logo';
import LockIcon from '../ui/images/LockIcon';
import EyeIcon from '../ui/images/EyeIcon';

export interface LoginData {
  password: string;
  confPassword: string;
}

const SetPassword = () => {
  const [hide, setHide] = useState(true);
  const navigate = useNavigate();

  const [loginData, setAuthData] = useState<LoginData>({
    password: '',
    confPassword: ''
  });

  const [errorMsg, setErrorMsg] = useState({
    passwordErr: false,
    passwordMatchErr: false
  });

  const [errMsgres, setErrMsgres] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setErrorMsg({
      passwordErr: false,
      passwordMatchErr: false 
    });

    let hasError = false;

    if (!loginData.password) {
      setErrorMsg((prev) => ({ ...prev, passwordErr: true }));
      hasError = true;
    }

    if (!loginData.confPassword) {
      setErrorMsg((prev) => ({ ...prev, passwordErr: true }));
      hasError = true;
    }

    if (loginData.password !== loginData.confPassword) {
      setErrorMsg((prev) => ({ ...prev, passwordMatchErr: true }));
      hasError = true;
    }

    if (hasError) return;

    try {
      const res = await setUserPassword(loginData);
      if (res.status === 200) {
        navigate('/', {replace: true });
      } else if (res.status === 400) {
        setErrMsgres(res.data.message);
      } else {
        console.log("Login unsuccessful");
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "An error occurred during login";
      setErrMsgres(errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleShowClick = () => {
    setHide(!hide);
  };

  const onHandlePrivacyPolicy = (route: any) => {
    navigate(route);
  };

  return (
    <form className={styles.login_main_wrap} onSubmit={handleSubmit}>
      <div className={styles.logn_sub}>
        <div>
          <Logo />
        </div>
        <h1 className={styles.login}>Enter the Password</h1>
        <section className={styles.input_section}>
          <div className={styles.password}>
            <span><LockIcon /></span>
            <input
              type={hide ? "password" : "text"}
              name="password"
              className={styles.logInp}
              id="password"
              placeholder='Password'
              onChange={handleInputChange}
              value={loginData.password}
            />
            <span className={styles.eyeIcon} onClick={handleShowClick}>
              {hide ? <EyeIcon /> : <EyeIcon />}
            </span>
          </div>
          {errorMsg.passwordErr && <span className={styles.errormsg}>Please enter a valid password</span>}
          <div className={styles.password}>
            <span><LockIcon /></span>
            <input
              type={hide ? "password" : "text"}
              name="confPassword"
              className={styles.logInp}
              id="confPassword"
              placeholder='Confirm Password'
              onChange={handleInputChange}
              value={loginData.confPassword}
            />
            <span className={styles.eyeIcon} onClick={handleShowClick}>
              {hide ? <EyeIcon /> : <EyeIcon />}
            </span>
          </div>
          {errorMsg.passwordMatchErr && <span className={styles.errormsg}>Passwords do not match</span>}
          {errMsgres && <span className={styles.errormsg}>{errMsgres === "INVALID_PASSWORD" ? errMsgres : ""}</span>}
        </section>
        <div className={styles.fp_cont}>
          <p className={styles.fp_lf}>
            <input className={styles.fp_checkBox} type="checkbox" />
            <span className={styles.fpText1}>Remember password</span>
          </p>
          <p className={styles.fpText2}>Forgot Password?</p>
        </div>
      </div>

      <section className={styles.btn_section}>
        <p className={styles.terms} onClick={() => onHandlePrivacyPolicy("/privacypolicy")}>T&C & Privacy Policy</p>
        <button className={styles.lg_btn}>Submit</button>
      </section>
    </form>
  );
}

export default SetPassword;
