import React, { useEffect, useState } from 'react'
import styles from "./userbalamce.module.scss"
import { useDispatch, useSelector } from "../../redux/store";
import { userBalance } from '../../redux/slice/userBalanceSlice';
import IdIcon from '../ui/images/IdIcon';
import UserIconBal from '../ui/images/UserIconBal';
import { getuserInfo } from '../../api/api';
import { useLocation } from 'react-router-dom';

const UserBalance = ({balancetype}:any) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const balanceState = useSelector((state) => state.userReducer)
    let balance:any
    if(location.pathname === "/withdraw"){
        balance = (balanceState?.userBalance?.withdrawls / 100);
    }else{
        balance = (balanceState?.userBalance?.withdrawls / 100 + balanceState?.userBalance?.deposit);
    }
    
    const userId = localStorage.getItem("userId")
    const [userData, setUserData] = useState<any>({});

    const formattedBalance = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
    }).format(balance);

    const fetchuserData = async () => {
        const res = await getuserInfo();
        setUserData(res?.data?.data);
      };

    useEffect(() => {
        dispatch(userBalance());
        fetchuserData();
    }, [dispatch]);
    return (
        <>
            <main className={styles.user_bal_main_wrap}>
                <section className={styles.user_section}>
                    <img src={userData?.profilePhotoPath} alt='user_image' width="70px" height="70px"/>
                    <div>
                        <h2 className={styles.name}>{userData?.nickName}</h2>
                        <div className={styles.user_id}>
                            <IdIcon width={20} heigth={20}/>
                            <p className={styles.id}>UID:{userId}</p>
                        </div>
                    </div>
                </section>

                <section className={styles.user_balance_sec}>
                    <p className={styles.user_bal_amt}>{formattedBalance}</p>
                    <p className={styles.avl_bal}>{balancetype}</p>
                </section>
            </main>
        </>
    )
}

export default UserBalance
