import React from 'react'

const IdIcon = ({width,height}:any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0514934 3.1667C3.70014 3.29129 7.03237 2.5319 9.95129 0.481138C10.8511 -0.15169 11.4543 -0.143779 12.362 0.407968C15.3442 2.22537 18.54 3.44752 22.0996 3.36051C22.8412 10.903 20.5907 19.9188 12.5933 23.6229C11.5413 24.1093 10.687 24.137 9.64477 23.6367C4.76013 21.2952 2.07061 17.2867 0.830663 12.1746C0.122687 9.25569 -0.116601 6.28338 0.0514934 3.1667Z" fill="black"/>
    <path d="M9.74487 16.9414C8.46442 15.1125 7.50461 13.6067 4.49553 11.5288C6.97313 12.3171 8.46234 13.0932 9.43628 13.5069C13.2069 9.5516 15.1443 7.9497 19.349 6.46393C13.2618 9.98099 10.7188 14.7994 9.74487 16.9414Z" fill="white"/>
    </svg>
  )
}

export default IdIcon
