import React, { useRef, useState } from 'react';
import styles from "./passwordreset.module.scss";
import axios from 'axios';
import { toast } from 'react-toastify';
import { replace, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login, resetrequestOtp, setUserPassword } from '../../api/api';
import Logo from '../ui/images/Logo';
import LockIcon from '../ui/images/LockIcon';
import EyeIcon from '../ui/images/EyeIcon';

export interface LoginData {
  password: string;
  confPassword: string;
}

const PasswordReset = () => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [hide, setHide] = useState(true);
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState<LoginData>({
    password: '',
    confPassword: ''
  });

  const [errorMsg, setErrorMsg] = useState({
    passwordErr: false,
    passwordMatchErr: false
  });

  const [errMsgres, setErrMsgres] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return;
    const updatedOtp = [...otp];
    updatedOtp[index] = value.slice(-1);
    setOtp(updatedOtp);

    if (value && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const updatedOtp = [...otp];
      updatedOtp[index] = '';
      setOtp(updatedOtp);

      if (index > 0 && otp[index] === '') {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSubmitRestPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    const otpValue = otp.join('');
    let hasError = false;

    if (!loginData.password) {
      setErrorMsg((prev) => ({ ...prev, passwordErr: true }));
      hasError = true;
    }

    if (!loginData.confPassword) {
      setErrorMsg((prev) => ({ ...prev, passwordErr: true }));
      hasError = true;
    }

    if (loginData.password !== loginData.confPassword) {
      setErrorMsg((prev) => ({ ...prev, passwordMatchErr: true }));
      hasError = true;
    }

    if (hasError) return;
   
    try {
      const res = await resetrequestOtp({
        token: Cookies.get('accessToken'),
        otp: otpValue,
        password:loginData?.password
      });
      if (res?.status === 200) {
        setErrorMsg({
          passwordErr: false,
        passwordMatchErr: false
        });
        navigate('/login', { state: { fromReset: true }, replace: true });
      } else if (res?.status === 400) {
        setErrMsgres(res?.data?.message);
      } else {
        console.log("Login unsuccessful");
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "An error occurred during login";
      setErrMsgres(errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleShowClick = () => {
    setHide(!hide);
  };

  const onHandlePrivacyPolicy = (route: any) => {
    navigate(route);
  };

  return (
    <form className={styles.login_main_wrap} onSubmit={handleSubmitRestPassword}>
      <div className={styles.logn_sub}>
        <div>
          <Logo />
        </div>
        <h1 className={styles.login}>Enter the Password</h1>
        <section className={styles.input_section}>
          <div className={styles.otp_box_wrap}>
            {otp.map((_, index) => (
              <input
                key={index}
                type="text"
                className={styles.otpbox}
                maxLength={1}
                value={otp[index]}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputsRef.current[index] = el)}
              />
            ))}
          </div>
          <div className={styles.password}>
            <span><LockIcon /></span>
            <input
              type={hide ? "password" : "text"}
              name="password"
              className={styles.logInp}
              id="password"
              placeholder='Password'
              onChange={handleInputChange}
              value={loginData.password}
            />
            <span className={styles.eyeIcon} onClick={handleShowClick}>
              {hide ? <EyeIcon /> : <EyeIcon />}
            </span>
          </div>
          {errorMsg.passwordErr && <span className={styles.errormsg}>Please enter a valid password</span>}
          <div className={styles.password}>
            <span><LockIcon /></span>
            <input
              type={hide ? "password" : "text"}
              name="confPassword"
              className={styles.logInp}
              id="confPassword"
              placeholder='Confirm Password'
              onChange={handleInputChange}
              value={loginData.confPassword}
            />
            <span className={styles.eyeIcon} onClick={handleShowClick}>
              {hide ? <EyeIcon /> : <EyeIcon />}
            </span>
          </div>
          {errorMsg.passwordMatchErr && <span className={styles.errormsg}>Passwords do not match</span>}
          {errMsgres && <span className={styles.errormsg}>{errMsgres === "INVALID_PASSWORD" ? errMsgres : ""}</span>}
        </section>
      </div>

      <section className={styles.btn_section}>
        <p className={styles.terms} onClick={() => onHandlePrivacyPolicy("/privacypolicy")}>T&C & Privacy Policy</p>
        <button className={styles.lg_btn}>Submit</button>
      </section>
    </form>
  );
}

export default PasswordReset;
