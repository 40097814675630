import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'; // For React Router, use `useRouter` if you're using Next.js

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'brandId': process.env.REACT_APP_API_BRAND_ID,
  },
});

// Error messages indicating unauthorized state
const UNAUTHORIZED_MESSAGES = [
  "INVALID_TOKEN",
  "AUTHORIZATION_TOKEN_SHOULD_NOT_BE_EMPTY",
  "INVALID_USER",
];

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      config.headers['Authorisation'] = accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor for error handling
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      const errorMessage = response.data.message;

      // Check if the error message matches unauthorized messages
      if (UNAUTHORIZED_MESSAGES.includes(errorMessage)) {
        // Clear cookies
        Cookies.remove("accessToken");
        Cookies.remove("userId");
        const navigate = useNavigate();
        navigate("/login");
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
