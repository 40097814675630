import React from 'react'
import styles from './carousel.module.scss'
import Carousel from "react-multi-carousel";
import banner1 from "../../assets/images/banner1.webp"
import banner2 from "../../assets/images/Banner 2 2.png"
import banner3 from "../../assets/images/Banner 4 (1).png"
import banner4 from "../../assets/images/Banner 5.png"

const CarouselSlider = () => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    
  return (
    <>
      <div className={styles.carousel_cont}>
        <Carousel 
        responsive={responsive} 
        autoPlay={true} 
        infinite={true} 
        removeArrowOnDeviceType={["tablet", "mobile"]} 
        draggable={true}
        showDots={true}
        >
          <div style={{ borderRadius: "0.3rem" }}><img style={{ borderRadius: "0.3rem" }} src={banner1} alt="" /></div>
          <div style={{ borderRadius: "0.3rem" }}><img style={{ borderRadius: "0.3rem" }} src={banner2} alt="" /></div>
          <div style={{ borderRadius: "0.3rem" }}><img style={{ borderRadius: "0.3rem" }} src={banner3} alt="" /></div>
          <div style={{ borderRadius: "0.3rem" }}><img style={{ borderRadius: "0.3rem" }} src={banner4} alt="" /></div>
        </Carousel>
      </div> 
    </>
  )
}

export default CarouselSlider
