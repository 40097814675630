import React from 'react'
import styles from './terms.module.scss'
import Navbar from '../navbar/Navbar'

const TermsandCondition = () => {
  return (
    <>
    <Navbar name={"Terms and Condition"}/>
      <main className={styles.terms_main_wrap}>
        
            <section className={styles.termscondition_wrap}>
                {/* <h1 className={styles.terms_heading}>Terms and Condition</h1> */}

                <div>
                    <h2 className={styles.terms_subheading}>Consent to Privacy Policy</h2>
                    <p className={styles.content}>By using the Services, you agree to the terms of this Privacy Policy. We reserve the right to amend this Privacy Policy and changes will come into effect within 14 days of such change being published on the Site. Your continued use of the Service constitutes your agreement to this Privacy Policy and any amendments to the same thereinafter. The purpose of this Privacy Policy is to enable you to understand how we collect, store, use and safeguard your Personal Information and for you to understand your rights in relation to that information. References to defined terms hereunder, if not specified, shall have the same meaning as set out in the Agreement.</p>
                </div>

                <div>
                    <h2 className={styles.terms_subheading}>What Information do We collect?</h2>
                    <p className={styles.content}>Our primary goal of collecting your information is to provide You a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet Your needs, and to makeYour experience safer and easier. When You visit this Site You may provide us with Your: • Personal Information: Name, images, mobile number, email id and content which You knowingly disclose that is collected on an individual basis. • Billing Information: Complete name; complete address including city and pin code; contact number and email address; and the GSTN to create invoices on Your behalf. This Billing information as disclosed by You and collected by Us, where applicable and on being requested by You, will be used to make payments and/or raise any invoices, if any. • Automatic Information: We store all such information which automatically gets accessible to Us by the virtue of visiting the Site or downloading our application on Your device, like location, email-id etc..</p>
                </div>

                <div>
                    <h2 className={styles.terms_subheading}>Use of Information</h2>
                    <p className={styles.content}>We use Your Information for understanding Users in such a way as to provide better Services at our Site; • We use Your Information to customize Your experience; detect and protect Us against error, fraud and other criminal activity; enforce Our terms and conditions; and as otherwise described to You at the time of collection; • We use Your Information continually to improve Our Product and Service offerings.</p>
                </div>

                <div>
                    <h2 className={styles.terms_subheading}>Eligibility</h2>
                    <p className={styles.content}>Services on the Platform would be available to India. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, undischarged insolvents etc. are not eligible to use the Platform. We reserve the right to terminate or refuse Your registration, or refuse to permit access to the Site, if it is discovered or brought to its notice that You are a minor.</p>
                </div>

                <div>
                    <h2 className={styles.terms_subheading}>What types of information are not allowed on Site?</h2>
                    <p className={styles.content}>Nudity or other sexually suggestive content; • Hate speech; • credible threats or direct attacks on an individual or group; • Content that contains self-harm or excessive violence; • Fake or impostor profiles; and • Spam.</p>
                </div>

                <div>
                    <h2 className={styles.terms_subheading}>Technical and functional management of the Site</h2>
                    <p className={styles.content}>When You use Our Site, We may also process technical data such as Your IP-address, Device ID or MAC-address, and information about the manufacturer, model, and operating system of Your device. We use this data to enable us to deliver the functionalities of the Site, resolve technical difficulties, and provide You with the correct (prevent the transmission of spam, viruses and/or unlawful, defamatory, obscene or otherwise undesirable material or content) and most up to date version of the Site.</p>
                </div>
            </section>
      </main>
    </>
  )
}

export default TermsandCondition
