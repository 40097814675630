import React from 'react'

const Withdraw = () => {
  return (
    <svg width="30" height="28" viewBox="0 0 245 219" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0768 93.0513V47.9528C31.0768 36.8209 38.7835 30.256 48.4882 30.256H196.343C211.756 30.256 213.754 44.8131 213.754 48.5237V93.6222C271.983 65.0789 237.731 0 200.624 0H43.9213C5.10267 0 -25.4384 67.9332 31.0768 93.0513ZM49.3449 47.9528V193.524C49.3449 214.361 66.1855 218.928 76.7465 218.928H170.94C189.207 218.928 195.487 203 195.487 195.237V48.5237L49.3449 47.9528ZM112.57 97.7285V149.677L102.294 139.973C93.1602 134.549 85.3964 147.166 90.8767 153.103L110.001 172.512C119.42 179.077 127.698 178.221 135.975 172.512L154.814 154.244C161.379 144.825 150.475 134.264 142.255 139.973L131.979 149.677V97.7285C131.979 86.0258 112.57 86.3112 112.57 97.7285Z" fill="#111"/>
    </svg>
  )
}

export default Withdraw
