import React, { useEffect, useState } from "react";
import styles from "./editUserProfile.module.scss";
import UserIcon from "../ui/images/UserIcon";
import { editUserData, postProfileIcon, userProfileData } from "../../api/api";
import moment from "moment";
import { Select_Month } from "../../config/date";
import { toast } from "react-toastify";
import Phone from "../ui/images/Phone";
import Navbar from "../navbar/Navbar";
import ProfileEditIcon from "../ui/images/ProfileEditIcon";
import IdIcon from "../ui/images/IdIcon";
import logo from "../ui/images/myAvatar (1).png";
import logo1 from "../ui/images/myAvatar (2).png";
import logo2 from "../ui/images/myAvatar (4).png";
import logo3 from "../ui/images/myAvatar.png";
import logo4 from "../ui/images/1.png";
import logo5 from "../ui/images/2.png";
import logo6 from "../ui/images/3.png";
import logo7 from "../ui/images/4.png";
import logo8 from "../ui/images/5.png";
import logo9 from "../ui/images/6.png";
import logo10 from "../ui/images/7.png";
import logo11 from "../ui/images/8.png";
import logo12 from "../ui/images/9.png";
import logo13 from "../ui/images/10.png";
import logo14 from "../ui/images/myAvatar (9).png";
import UserAvatar from "../useravatar/UserAvatar";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Helmet } from "react-helmet";


export interface userDetails {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  nickName: string;
  mobile: string;
  profilePhotoPath: string;
  userId: string;
}

const EditUserProfile = () => {
  const [userData, setUserData] = useState<userDetails>({
    firstName: "",
    lastName: "",
    nickName: "",
    birthDate: "",
    gender: "",
    mobile: "",
    profilePhotoPath: "",
    userId: "",
  });
  const [personalData, setPersonalData] = useState<any>();
  const [genderType, setGender] = useState(personalData?.data?.gender ?? "");
  const [editEnabled, setEditEnabled] = useState(false);
  const [birthDate, setBirthDate] = useState("");
  const [rawDate, setRawDate] = useState<string>("");
  const [avatarlist, setAvatarList] = useState<boolean>(false);
  const [selectedAvatarId, setSelectedAvatarId] = useState<any>(1);
  const [confirmedAvatarId, setConfirmedAvatarId] = useState<number>(1);

  const editUsers = async () => {
    try {
      const res = await userProfileData();
      if (res?.status === 200) {
        const {
          firstName,
          lastName,
          nickName,
          birthDate,
          gender,
          mobile,
          userId,
          profilePhotoPath,
        } = res?.data?.data;
        setUserData({
          ...userData,
          firstName,
          lastName,
          nickName,
          birthDate,
          gender,
          mobile,
          userId,
          profilePhotoPath,
        });
        setPersonalData(res.data.data);
        setGender(res.data.data.gender);
        if (res.data.data.birthDate != null || undefined) {
          setRawDate(
            new Date(res.data.data.birthDate).toISOString().split("T")[0]
          );
        }
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    editUsers();
  }, [confirmedAvatarId]);

  const updateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleButtonClick = (gender: any) => {
    setUserData({
      ...userData,
      gender: gender,
    });
    setGender(gender);
  };
  const handleDateChange = (newValue: Dayjs | null) => {
    setRawDate(String(newValue));
  };
  // const handleDateChange = (event: any) => {
  //   const selectedDate = event.target.value;
  //   setRawDate(selectedDate);


  //   const date = new Date(selectedDate);
  //   if (!isNaN(date.getTime())) {
  //     const formattedDate = `${date.getFullYear()}/${date.toLocaleString(
  //       "default",
  //       {
  //         month: "long",
  //       }
  //     )}/${String(date.getDate()).padStart(2, "0")}`;
  //     setBirthDate(formattedDate);
  //   }
  // };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    userData["birthDate"] = dayjs(rawDate).format("YYYY-MM-DD");
    userData["gender"] = genderType;
    let body = { ...userData };
    setEditEnabled(!editEnabled);
    let res;
    try {
      res = await editUserData(body);
      if (res.status === 200) {
        toast.success("user updated successfully");
      }
    } catch (error) {
      return error;
    }
  };

  const handleShowAvatar = () => {
    setAvatarList(!avatarlist);
  };

  const userAvatars = [
    { id: 1, image: logo },
    { id: 2, image: logo1 },
    { id: 3, image: logo2 },
    { id: 4, image: logo3 },
    { id: 5, image: logo4 },
    { id: 6, image: logo5 },
    { id: 7, image: logo6 },
    { id: 8, image: logo7 },
    { id: 9, image: logo8 },
    { id: 10, image: logo9 },
    { id: 11, image: logo10 },
    { id: 12, image: logo11 },
    { id: 13, image: logo12 },
    { id: 14, image: logo13 },
    { id: 15, image: logo14 },
  ];

  const handleAvatarConfirmation = async (e: any) => {
    e.preventDefault()
    try {
      const selectedAvatar = userAvatars.find(
        (avatar: any) => avatar.id === selectedAvatarId
      );
      if (!selectedAvatar) {
        console.error("Selected avatar not found");
        return;
      }

      // Fetch the image and convert it to a Blob
      const response = await fetch(selectedAvatar.image);
      const blob = await response.blob();
      const file = new File([blob], `avatar-${selectedAvatarId}.png`, {
        type: blob.type,
      });

      // Prepare FormData
      const formData = new FormData();
      formData.append("profilePhoto", file);

      const res = await postProfileIcon(formData);
      console.log("Avatar updated successfully:", res.data);
      setConfirmedAvatarId(selectedAvatarId);
      setAvatarList(false);
    } catch (error) {
      console.error("Error updating avatar:", error);
    }
    editUsers();
  };

  return (
    <>
      <Helmet>
        <title>{"Rapid Games Edit Profile"}</title>
        <meta
          name="description"
          content="Easily edit your Rapid Games profile. Update personal details, manage account settings, and customize your gaming experience for a seamless journey."
        />
        <meta
          name="keywords"
          content="Rapid Games, edit profile, gaming account settings, update profile, customize gaming, account management, gaming personalization"
        />
      </Helmet>
      <form className={styles.editUSer_mainCont}>
        <div className={styles.nav_GCont}>
          <Navbar name={"User Profile"} />
        </div>

        <section className={styles.user_infoCont}>
          <div className={styles.user_profile_imageCont}>
            <img
              src={
                userData?.profilePhotoPath !== ""
                  ? userData?.profilePhotoPath
                  : userAvatars[0]?.image
              }
              alt="user_image"
              width={"100px"}
              height={"100px"}
            />
            <div className={styles.edit_profil_icon} onClick={handleShowAvatar}>
              <ProfileEditIcon />
            </div>
          </div>

          <h1 className={styles.username}>{userData?.nickName}</h1>
          <div className={styles.border_bottm}></div>
          <div className={styles.userId_info}>
            <IdIcon width={22} heigth={22} />
            <h2 className={styles.userId}>UID:{userData?.userId}</h2>
          </div>
        </section>

        {avatarlist ? (
          <UserAvatar
            selectedAvatarId={selectedAvatarId}
            setSelectedAvatarId={setSelectedAvatarId}
            onConfirm={handleAvatarConfirmation}
          />
        ) : (
          <>
            <div className={styles.logn_sub}>
              <section className={styles.input_section}>
                <div className={styles.username}>
                  <span>
                    <UserIcon />
                  </span>
                  <input
                    type="text"
                    name="firstName"
                    className={styles.logInp}
                    id="firstName"
                    disabled={!editEnabled}
                    placeholder="firstName"
                    value={userData.firstName}
                    onChange={updateInputChange}
                  />
                </div>
                <div className={styles.password}>
                  <span>
                    <UserIcon />
                  </span>
                  <input
                    type="text"
                    name="lastName"
                    className={styles.logInp}
                    id="lastName"
                    placeholder="lastName"
                    disabled={!editEnabled}
                    value={userData.lastName}
                    onChange={updateInputChange}
                  />
                </div>
                <div className={styles.password}>
                  <span>
                    <UserIcon />
                  </span>
                  <input
                    type="text"
                    name="nickName"
                    className={styles.logInp}
                    id="nickName"
                    placeholder="nickName"
                    disabled={!editEnabled}
                    value={userData.nickName}
                    onChange={updateInputChange}
                  />
                </div>

                <div className={styles.password}>
                  <span>
                    <Phone />
                  </span>
                  <input
                    type="text"
                    name="mobile"
                    className={styles.logInp}
                    id="mobile"
                    placeholder="mobile"
                    disabled={true}
                    value={userData.mobile}
                  />
                </div>
                <div className={styles.datePicker}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker name="birthDate"
                      value={dayjs(rawDate)}
                      onChange={handleDateChange} disabled={!editEnabled} />
                  </DemoContainer>
                </div>

                <div className={styles.gender_cont}>
                  <div className={styles.gender_cont_fields}>
                    <div className={styles.individual_field}>
                      <input
                        type="radio"
                        checked={genderType === "MALE"}
                        value={genderType}
                        name="genderType"
                        className={styles.radio_btn}
                        onClick={() => handleButtonClick("MALE")}
                        disabled={!editEnabled}
                      />
                      <p className={styles.gender_label}>Male</p>
                    </div>
                    <div className={styles.individual_field}>
                      <input
                        type="radio"
                        checked={genderType === "FEMALE"}
                        value={genderType}
                        name="genderType"
                        className={styles.radio_btn}
                        onClick={() => handleButtonClick("FEMALE")}
                        disabled={!editEnabled}
                      />
                      <p
                        className={styles.gender_label}
                        color={
                          genderType === "FEMALE"
                            ? "--button-normal-grad"
                            : "inactive"
                        }
                      >
                        Female
                      </p>
                    </div>
                    <div className={styles.individual_field}>
                      <input
                        type="radio"
                        checked={genderType === "OTHER"}
                        value={genderType}
                        name="genderType"
                        className={styles.radio_btn}
                        onClick={() => handleButtonClick("OTHER")}
                        disabled={!editEnabled}
                      />
                      <p
                        className={styles.gender_label}
                        color={
                          genderType === "OTHER"
                            ? "--button-normal-grad"
                            : "inactive"
                        }
                      >
                        Other
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section className={styles.uproBtn_section}>
              {!editEnabled ? (
                <p
                  className={styles.lg_btn}
                  onClick={() => {
                    setEditEnabled(!editEnabled);
                  }}
                >
                  Edit User
                </p>
              ) : (
                <button className={styles.lg_btn} onClick={handleSubmit}>
                  save changes
                </button>
              )}
            </section>
          </>
        )}
      </form>
    </>
  );
};

export default EditUserProfile;
