import React from 'react'

const EyeIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.5397 10.2504C25.7886 10.5062 25.7886 10.9208 25.5397 11.1766L22.5014 14.2983C22.4903 14.3106 22.4788 14.3223 22.467 14.3336C19.7876 17.0865 17.1083 19.8394 14.4289 22.5923C14.4178 22.6046 14.4063 22.6164 14.3944 22.6277L11.3547 25.7509C11.1057 26.0067 10.7021 26.0067 10.4532 25.7509C10.2043 25.4952 10.2043 25.0805 10.4532 24.8247L12.9305 22.2795C11.6614 21.4605 10.5049 20.4683 9.49297 19.329L9.49059 19.3264C9.17103 18.9626 8.99414 18.4899 8.99414 17.9999C8.99414 17.5098 9.17103 17.0372 9.4906 16.6734L9.4968 16.6663C10.1943 15.8951 11.3443 14.7497 12.7951 13.7935C14.2426 12.8394 16.0327 12.0452 17.9977 12.0452H18.011C19.3718 12.0744 20.7067 12.4171 21.9182 13.0451L24.6382 10.2504C24.8872 9.99465 25.2907 9.99465 25.5397 10.2504ZM13.8571 21.3274C12.5882 20.5426 11.4357 19.5728 10.436 18.4476C10.3285 18.3247 10.269 18.1652 10.269 17.9999C10.269 17.8353 10.3279 17.6766 10.4344 17.5539C11.0913 16.828 12.1578 15.7697 13.4835 14.8959C14.8132 14.0195 16.3606 13.3568 17.991 13.355C19.017 13.3779 20.0257 13.608 20.9606 14.029C18.8936 16.1527 16.067 19.0569 13.8571 21.3274Z" fill="black"/>
<path d="M25.2432 15.3927C24.9809 15.1515 24.5779 15.1745 24.3432 15.4441C24.1084 15.7136 24.1308 16.1276 24.3932 16.3688C24.8621 16.7999 25.2589 17.22 25.5592 17.554C25.666 17.6768 25.7252 17.8358 25.7252 18.0006C25.7252 18.1652 25.6663 18.3239 25.5598 18.4466C24.9029 19.1725 23.8364 20.2307 22.5107 21.1046C21.1792 21.9822 19.6293 22.6455 17.9965 22.6455H17.4807C17.1286 22.6455 16.8432 22.9387 16.8432 23.3004C16.8432 23.6621 17.1286 23.9553 17.4807 23.9553H17.9965C19.9614 23.9553 21.7516 23.1611 23.199 22.207C24.6498 21.2508 25.7998 20.1053 26.4973 19.3341L26.5035 19.3271C26.8231 18.9633 27 18.4907 27 18.0006C27 17.5106 26.8231 17.0379 26.5035 16.6741L26.4987 16.6687C26.1803 16.3143 25.7529 15.8612 25.2432 15.3927Z" fill="black"/>
<path d="M20.1218 15.8787C15.8791 20.1213 20.1218 15.8787 15.8791 20.1213C14.7076 18.9497 14.7076 17.0503 15.8791 15.8787C17.0507 14.7071 18.9502 14.7071 20.1218 15.8787Z" fill="black"/>
</svg>
  )
}

export default EyeIcon
