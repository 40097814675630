import React from 'react'

const UserIcon = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_415_489)">
<path d="M14.0758 5.60137C14.0758 8.41878 11.7918 10.7027 8.97442 10.7027C6.15701 10.7027 3.87305 8.41878 3.87305 5.60137C3.87305 2.78396 6.15701 0.5 8.97442 0.5C11.7918 0.5 14.0758 2.78396 14.0758 5.60137ZM5.26877 5.60137C5.26877 7.64794 6.92784 9.30702 8.97442 9.30702C11.021 9.30702 12.6801 7.64794 12.6801 5.60137C12.6801 3.5548 11.021 1.89572 8.97442 1.89572C6.92784 1.89572 5.26877 3.5548 5.26877 5.60137Z" fill="black"/>
<path d="M17.8801 18.329C17.8801 15.9579 16.9382 13.6839 15.2616 12.0072C13.5849 10.3306 11.3109 9.38867 8.93976 9.38867C6.56863 9.38867 4.29461 10.3306 2.61796 12.0072C0.941319 13.6839 -0.000609994 15.9579 -0.000610352 18.329H1.37706C1.37706 16.3233 2.17384 14.3997 3.59212 12.9814C5.0104 11.5631 6.93401 10.7663 8.93976 10.7663C10.9455 10.7663 12.8691 11.5631 14.2874 12.9814C15.7057 14.3997 16.5025 16.3233 16.5025 18.329H17.8801Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_415_489">
<rect width="17.8462" height="18" fill="white" transform="translate(-0.00012207 0.5)"/>
</clipPath>
</defs>
</svg>
    )
}

export default UserIcon
