import React from 'react'
import "./bottombar.module.scss"
import { Link, useLocation } from 'react-router-dom'
import UserNav from '../ui/images/UserNav'
import WinIcon from '../ui/images/WinIcon'
import styles from "./bottombar.module.scss"
import Casino from '../ui/images/Casino'

const BottomNavbar = () => {

    const location = useLocation()

    return (
        <>
            <nav className={styles.nav}>
                <ul>
                <Link to={"/promotions"}>
                    <div className={`${styles.bBarFields} ${location.pathname === "/promotions" ? styles.active : ""}`}>
                        <WinIcon/>
                        <li>Promotions</li>
                    </div>
                    </Link>
                   
                    <Link to={"/"}>
                    <div className={`${styles.bBarFields} ${styles.casinoTab} ${location.pathname === "/" ? styles.active : ""}`}>
                        <Casino/>
                        <li>Casino</li>
                        </div>
                    </Link>
                    <Link to={"/userprofile"}>
                    <div className={`${styles.bBarFields} ${location.pathname === "/userprofile" ? styles.active : ""}`}>
                        <UserNav />
                        <li>Me</li>
                        </div>
                    </Link>
                </ul>
            </nav>
        </>
    )
}

export default BottomNavbar
