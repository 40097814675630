import React, { useEffect, useState } from "react";
import styles from "./withdrawhistory.module.scss";
import DepositeIcon from "../ui/images/DepositeIcon";
import { depositeHistory } from "../../api/api";
import Cookies from "js-cookie";
import Navbar from "../navbar/Navbar";
import Loading from "../ui/loading/Loading";
import HistoryIcon from "../ui/images/HistoryIcon";
import NoResult from "../ui/images/NoResult";
import PaginationComp from "../pagination/PaginationComp";
import { Helmet } from "react-helmet";
import { Modal } from "antd";
import CalenderDatePicker from "../../datepicker/CalenderDatePicker";

const WithDrawHistory = () => {
  const [loading, setLoading] = useState(true);
  const [depositedata, setDepositeData] = useState([]);
  let userId: any = Cookies.get("userId");
  let parseUserId = parseInt(userId);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 2)));
  const [endDate, setEndDate] = useState(new Date());
  const filters = [
    "ALL", "CONFIRMED", "FAILED", "DECLINED", "CALENDER"
  ]
  const [status, setStatus] = useState("")
  const [activeFilter, setActiveFilter] = useState<string | null>("ALL");
  const [modalOpen, setModalOpen] = useState(false);



  const fetchDepositeHistory = async (page: any) => {
    try {
      const res = await depositeHistory({
        userId: parseUserId,
        paymentType: "WITHDRAW",
        pageNumber: page - 1,
        itemPerPage: 15,
        status: status,
        startDate: startDate,
        endDate: endDate
      });
      if (res?.status === 200) {
        setLoading(false)
        const totalRecords = (Math.ceil((res?.data?.data?.totalRecords / 15))) ?? 0
        setTotalPages(totalRecords);
      }
      setDepositeData(res?.data?.data?.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchDepositeHistory(pageNumber);
  }, [status, pageNumber]);

  const handleFilterClick = (fname: string) => {
    if (fname === "CONFIRMED" || "FAILED" || "DECLINED") {
      setPageNumber(1)
    }
    const ffname = fname === "ALL" || "CALENDER" ? "" : fname
    setStatus(ffname)
    setActiveFilter(fname);
    if (fname === "CALENDER") {
      setModalOpen(true)
    }
  }

  const handleDateChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleSearchClick = () => {
    fetchDepositeHistory(1);
    setModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{"Rapid Games Withdraw History"}</title>
        <meta name="description" content="Review and manage all your withdrawal transactions on Rapid Games. Access a detailed history of your withdrawals, including dates, amounts, and transaction status. Stay in control of your gaming finances with our secure and transparent platform." />
        <meta name="keywords" content="withdraw history, withdrawal transactions, gaming withdrawals, transaction history, manage withdrawals, secure withdrawal platform, Rapid Games withdrawals, financial management, gaming finance tracking, withdrawal records, payment tracking" />

      </Helmet>
      <main className={styles.deposite_box_main_wrap}>
        <div className={styles.nav_GCont}>
          <Navbar name={"Withdraw Transaction"} />
        </div>
        <div className={styles.filters}>
          {filters?.map((fname: string, i: any) => {
            return (
              <div className={`${activeFilter === fname ? styles.filter_field_active : styles.filter_field}`} onClick={() => handleFilterClick(fname)} key={i}>{fname}</div>
            )
          })}
        </div>

        <Modal
          centered
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
        >
          <div className={styles.calender}>
            <CalenderDatePicker
              startDate={startDate}
              endDate={endDate}
              fetchAllTransaction={fetchDepositeHistory}
              onDateChange={handleDateChange}
              onSearch={handleSearchClick}
            />
          </div>
        </Modal>
        {loading ? <Loading /> :
          depositedata?.length === 0 ?
            <div className={styles.noresult_wrap}>
              <NoResult />
              <p className={styles.noresult}>No Withdraw Found</p>
            </div> : depositedata?.map((item: any) => {
              const updatedDate = new Date(item.updatedDate);
              const date = updatedDate.toLocaleDateString("en-IN");
              const time = updatedDate.toLocaleTimeString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
              });

              return (
                <section key={item.transactionId} className={styles.deposite_box}>
                  <div className={styles.deposite_left_side}>
                    <div className={styles.name_wrap}>
                      <HistoryIcon />
                      <h2 className={styles.payt_name}>
                        {item?.paymentGateway}</h2>
                    </div>
                    <p>Withdraw Amount</p>
                    <p>Transaction ID</p>
                    <p>{date} <span>{time}</span></p>
                  </div>

                  <div className={styles.deposite_right_side}>
                    <h2 className={styles.amt}>₹ {item?.amount / 100}</h2>
                    <p>₹ {item?.amount / 100}</p>
                    <p className={styles.transId}>{item?.transactionId}</p>
                    <p className={`${item.status === "FAILED" ? styles.failed : item.status === "CONFIRMED" ? styles.confirm : item.status === "DECLINED" ?
                      styles.declined : ""
                      }`}>{item?.status}</p>
                  </div>
                </section>
              );
            })}
        {
          depositedata.length !== 0 ?
            <PaginationComp
              totalPages={totalPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber} /> : ""
        }
      </main>
    </>
  );
};

export default WithDrawHistory;
