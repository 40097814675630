import React, { useEffect, useState } from "react";
import styles from "./withdraw.module.scss";
import Navbar from "../navbar/Navbar";
import UserBalance from "../userbalance/UserBalance";
import { useNavigate } from "react-router-dom";
import AddBank from "../ui/images/AddBank";
import { addBank, getBankAccts, isPrimary, withDrawAmount } from "../../api/api";
import { toast } from "react-toastify";
import tick from "../ui/images/tick.png"
import { useDispatch } from "../../redux/store";
import { setWithDrawDetails } from "../../redux/slice/WithdrawSlice";


const Withdraw = () => {
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentErrorRes, setPaymentErrorRes] = useState("");
  const [selectedBankData, setSelectedBankData] = useState<any>();
  const [bankName,setBankName] = useState("");
  const [countBanks, setCountBanks] = useState(0)
  const [bankId, setBankId] = useState(0)
  const navigate = useNavigate();
  let minvalue = 100;
  let maxvalue = 25000;
  const dispatch = useDispatch()
  
  console.log(bankName,"dsnbjdsbdoipoappaaa");
  

  const onHandlePay = async () => {
    const parseAmount = parseInt(amount);
    
    if (
      parseAmount < minvalue ||
      parseAmount > maxvalue ||
      isNaN(parseAmount)
    ) {
      setAmountError(true);
    } else {
      try {
        const res = await withDrawAmount({
          amount: parseAmount * 100,
          bankAccountId: bankId
        })
        if (res.status === 200) {
          dispatch(setWithDrawDetails({ amount: parseAmount, bankName:bankName }));
          navigate("/withdrawdetails");
        }
      } catch (error: any) {
        setPaymentError(true);
        setAmountError(false);
        const transformedString = error?.response?.data?.message.replace(/_/g, " ");
        setPaymentErrorRes(transformedString);
      }
    }
  };



  const getSelectedBank = async () => {
    try {
      const res = await getBankAccts();
      if (res.status === 200) {
        const bankData = res?.data?.data;
        setSelectedBankData(bankData);
        setCountBanks(bankData?.length || 0);

        const primaryBank = bankData.find((bank: any) => bank?.isPrimary === true);
        if (primaryBank) {
          setBankId(primaryBank.id);
          setBankName(primaryBank.bankName);
        }
      }
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  

  useEffect(() => {
    getSelectedBank();
  }, []);


  const setPrimaryBank = async (data: any) => {
    try {
      const res = await isPrimary(data);
      if (res?.status === 200) {
        toast.success("Primary bank updated");

        const updatedData = selectedBankData.map((bank: any) => ({
          ...bank,
          isPrimary: bank.id === data?.id,
        }));
        console.log(updatedData,"dsjdsbdiiiiiiiippp");
        
        setSelectedBankData(updatedData);
      }
    } catch (error) {
      toast.error("Failed to update primary bank");
    }
  }

  const handlePrimAccChange = (id: any) => {
    setBankId(id)
    const body = { id: id }
    setPrimaryBank(body)
  }

  const handleRouteClick = () => {
    navigate("/addbank")
  }

  return (
    <>
      <div>
        <h1 className={styles.nav_GCont}>
          <Navbar name={"Withdraw"} />
        </h1>
        <main className={styles.withdraw_main_wrap}>
          <section className={styles.user_bal_section}>
            <UserBalance balancetype={"Withdraw Balance"} />
          </section>
          <div>
            <div className={styles.add_accCont} onClick={handleRouteClick}>
              <span>
                <AddBank />
              </span>
              <span className={styles.ab_text}>{countBanks > 0 ? "Add New Bank Account" : "Add Bank Account"}</span>
            </div>

            <section className={styles.deposit_box_wrap}>
              <h3 className={styles.deposite}>Make a Withdraw</h3>
              <div>
                <input
                  type="number"
                  placeholder="Enter a Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className={styles.amt_input}
                />
                {amountError && (
                  <p className={styles.error_msg}>
                    Please Enter the valid amount
                  </p>
                )}
                {paymentError && (
                  <p className={styles.error_msg}>{paymentErrorRes}</p>
                )}

                <div className={styles.amt_val}>
                  <p className={styles.min}>Minimum - {minvalue}</p>
                  <p className={styles.min}>Maximum - {maxvalue}</p>
                </div>
              </div>
            </section>

            {
              countBanks >= 1 &&
              <div className={styles.selected_bank_cont}>
                <p className={styles.sb_text}>Select Bank Account</p>

                {selectedBankData?.map((bData: any, i: any) => {
                  return (
                    <div className={styles.banklist} key={bData?.id}>
                      <span>{bData?.bankName}</span>
                      <label className={styles.customRadio}>
                        <input
                          type="radio"
                          name="primaryBank"
                          value={bData?.id}
                          defaultChecked={bData?.isPrimary}
                          checked={bData?.id === bankId || bData?.isPrimary}
                          onChange={() => handlePrimAccChange(bData?.id)}
                        />
                        <span className={styles.radioTick}></span>
                      </label>
                    </div>
                  );
                })}
              </div>
            }

          </div>

          <section className={styles.pay_button_wrap}>
            <p className={styles.btn_info}>
              By completing, you confirm your acceptance of the Offer T&C and
              Bonus T&C
            </p>
            <button className={styles.pay_btn} onClick={onHandlePay}>
              Withdraw {amount}
            </button>
          </section>
        </main>
      </div>


    </>
  );
};

export default Withdraw;
