import React from 'react'

const DepositeIcon = () => {
    return (
        <svg width="30" height="28" viewBox="0 0 371 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M239.876 15.6075C217.947 -4.99623 182.189 -5.21602 160.008 15.1166L113.375 57.8649H281.037C278.448 53.1052 275.026 48.6334 270.771 44.6354L239.876 15.6075Z" fill="#111"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7822 140.948C33.7822 101.489 65.7696 69.502 105.228 69.502H299.143C338.602 69.502 370.589 101.489 370.589 140.948V157.158H317.869C298.122 157.158 282.115 173.166 282.115 192.912V207.139C282.115 226.886 298.122 242.893 317.869 242.893H370.589V251.74C370.589 291.198 338.602 323.186 299.143 323.186H149.752C157.173 311.647 161.478 297.915 161.478 283.177C161.478 242.273 128.319 209.115 87.4161 209.115C66.3135 209.115 47.2723 217.94 33.7822 232.102V140.948Z" fill="#111"/>
        <path d="M92.0302 242.036L83.0823 275.001C82.9808 275.376 83.04 275.473 83.0823 275.476H98.8638C102.255 275.476 103.338 277.83 101.454 280.421L62.366 327.514C60.4823 330.905 54.5955 331.517 56.7147 324.924L66.8399 289.839L56.0033 288.662C51.859 288.285 50.8283 286.072 53.8841 281.833L85.437 241.565C88.6394 237.044 93.2075 237.329 92.0302 242.036Z" fill="#111"/>
        <path d="M299.141 199.248C299.141 183.913 311.572 171.482 326.907 171.482H370.587V228.639H326.907C311.572 228.639 299.141 216.208 299.141 200.874V199.248Z" fill="#111"/>
        <path d="M163 286.5C163 331.511 126.511 368 81.5 368C36.4888 368 0 331.511 0 286.5C0 241.489 36.4888 205 81.5 205C126.511 205 163 241.489 163 286.5ZM13.0618 286.5C13.0618 324.297 43.7026 354.938 81.5 354.938C119.297 354.938 149.938 324.297 149.938 286.5C149.938 248.703 119.297 218.062 81.5 218.062C43.7026 218.062 13.0618 248.703 13.0618 286.5Z" fill="#111"/>
        </svg>

    )
}

export default DepositeIcon
