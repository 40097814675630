import React from 'react'

const HistoryIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.896 8.18875V0.870458C10.896 0.225674 10.406 0 9.99339 0H3.83617L0 3.32063V14.1852C0 15.1202 0.702761 15.1524 1.06381 15.1524H6.64077C6.64077 12.4121 6.64077 8.18875 10.896 8.18875ZM1.03156 3.51181H2.83681C3.77168 3.51181 4.02957 2.9702 4.02957 2.3512V0.771484L1.03156 3.51181ZM4.63089 3.89062C4.37624 3.89062 4.1698 3.68419 4.1698 3.42953C4.1698 3.17488 4.37624 2.96844 4.63089 2.96844H8.85215C9.10681 2.96844 9.31325 3.17488 9.31325 3.42953C9.31325 3.68419 9.10681 3.89062 8.85215 3.89062H4.63089ZM1.4339 5.27146C1.4339 5.52611 1.64034 5.73255 1.89499 5.73255H8.30854C8.5632 5.73255 8.76963 5.52611 8.76963 5.27146C8.76963 5.0168 8.5632 4.81036 8.30854 4.81036H1.89499C1.64034 4.81036 1.4339 5.0168 1.4339 5.27146ZM1.90103 7.49414C1.64304 7.49414 1.4339 7.285 1.4339 7.02701C1.4339 6.76902 1.64304 6.55988 1.90103 6.55988H8.34687C8.60486 6.55988 8.814 6.76902 8.814 7.02701C8.814 7.285 8.60486 7.49414 8.34687 7.49414L1.90103 7.49414ZM1.43402 12.3635C1.43402 12.6309 1.65077 12.8477 1.91814 12.8477H5.36041C5.62778 12.8477 5.84453 12.6309 5.84453 12.3635C5.84453 12.0962 5.62778 11.8794 5.36041 11.8794H1.91814C1.65077 11.8794 1.43402 12.0962 1.43402 12.3635ZM1.84802 10.9472C1.61939 10.9472 1.43404 10.7619 1.43404 10.5332C1.43404 10.3046 1.61938 10.1192 1.84802 10.1192H5.40798C5.63662 10.1192 5.82197 10.3046 5.82197 10.5332C5.82197 10.7619 5.63662 10.9472 5.40798 10.9472H1.84802ZM1.43402 8.83172C1.43402 9.08323 1.6379 9.28711 1.88941 9.28711H5.36657C5.61807 9.28711 5.82195 9.08323 5.82195 8.83172C5.82195 8.58022 5.61807 8.37634 5.36657 8.37634H1.88941C1.6379 8.37634 1.43402 8.58022 1.43402 8.83172Z" fill="black"/>
    <path d="M15.27 10.1438L12.479 7.98395C12.2663 7.85103 12.0138 7.98397 12.0138 8.15009V9.18019H10.9173C8.68451 9.44602 7.66113 11.0742 7.29563 11.9382C7.24247 12.1509 7.40438 12.204 7.49499 12.0047C7.6279 11.7122 7.81618 11.3955 7.89371 11.2736C8.0532 11.0344 8.19275 11.2138 8.1263 11.3069C7.72758 11.8651 7.56145 12.6249 7.52823 12.9351C7.50165 13.2009 7.69435 13.2009 7.72759 13.1012C7.76081 11.7388 8.9902 9.97769 10.9838 9.81154H12.3793C12.5787 9.81154 12.6451 9.71186 12.6451 9.61217V8.8479L14.6387 10.4761L12.6451 12.0379V11.1739C12.6451 10.9879 12.4987 10.8749 12.3793 10.8749H10.718C10.1531 10.8749 9.59936 11.4287 9.32247 11.7056L9.32246 11.7056C9.13639 11.9714 9.48859 12.2705 9.75441 12.1376C10.0468 11.8452 10.6183 11.473 10.9173 11.473H12.0138V12.7025C12.0138 12.9949 12.3129 13.1012 12.5122 12.9683L15.27 10.7752C15.5624 10.5625 15.4694 10.3432 15.27 10.1438Z" fill="black"/>
    <path d="M7.81348 15.7839L10.6045 17.9438C10.8172 18.0767 11.0697 17.9438 11.0697 17.7776V16.7475H12.1662C14.399 16.4817 15.4224 14.8535 15.7879 13.9895C15.841 13.7769 15.6791 13.7237 15.5885 13.9231C15.4556 14.2155 15.2673 14.5323 15.1898 14.6541C15.0303 14.8934 14.8907 14.7139 14.9572 14.6209C15.3559 14.0626 15.522 13.3028 15.5553 12.9927C15.5818 12.7268 15.3891 12.7268 15.3559 12.8265C15.3227 14.1889 14.0933 15.95 12.0997 16.1162H10.7042C10.5048 16.1162 10.4384 16.2159 10.4384 16.3156V17.0798L8.44479 15.4516L10.4384 13.8898V14.7538C10.4384 14.9399 10.5847 15.0529 10.7042 15.0529H12.3655C12.9304 15.0529 13.4841 14.4991 13.761 14.2221L13.761 14.2221C13.9471 13.9563 13.5949 13.6572 13.3291 13.7902C13.0367 14.0826 12.4652 14.4547 12.1662 14.4547H11.0697V13.2253C11.0697 12.9328 10.7706 12.8265 10.5713 12.9594L7.81348 15.1525C7.52109 15.3652 7.61412 15.5845 7.81348 15.7839Z" fill="black"/>
    </svg>
  )
}

export default HistoryIcon
