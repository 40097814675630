import React from 'react'

const WinIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 258 257" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.4387 15.0586V0H1.00994C-1.43782 24.0938 5.84624 95.873 80.5695 129.253C73.5422 124.233 62.5842 106.414 57.9836 98.1318C33.95 79.0576 18.3894 46.6816 16.3816 15.0586H46.4387ZM61.4973 0V50.1953C61.4973 99.8887 101.905 134.272 129.261 142.304C161.888 132.265 196.774 96.9773 196.774 50.1953V0H61.4973ZM212.083 0V15.0586H242.702C240.755 42.415 228.959 73.7871 200.538 98.1318C196.272 106.665 183.723 124.735 177.95 129.253C257.51 94.1162 259.327 15.0586 257.51 0H212.083ZM129.261 158.366L144.38 152.594V181.707H187.046V241.941H202.104V257H56.5379V241.941H71.5965V181.707H114.262V152.594L129.261 158.366ZM151.909 206.805H106.734V221.863H151.909V206.805Z" fill="#111" />
        </svg>
    )
}

export default WinIcon
