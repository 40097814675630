import React, { useState } from 'react';
import { DateRange } from '@umakantp/react-date-range';
import '@umakantp/react-date-range/dist/styles.css';
import '@umakantp/react-date-range/dist/theme/default.css';
import { enUS } from 'date-fns/locale';
import styles from './calender.module.scss';

const CalenderDatePicker = ({ startDate, endDate, onDateChange, onSearch }: any) => {
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    },
  ]);

  const handleDateChange = (item: any) => {
    const { startDate, endDate } = item.selection;
    setState([item.selection]);
    onDateChange(startDate, endDate);
  };

  return (
    <>
      <DateRange
        editableDateInputs
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        ranges={state}
        locale={enUS}
      />
      <section className={styles.pay_button_wrap}>
        <button className={styles.pay_btn} onClick={onSearch}>
          Search
        </button>
      </section>
    </>
  );
};

export default CalenderDatePicker;
