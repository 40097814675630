import React from 'react'
import styles from './pagenotfound.module.scss'
import { Helmet } from 'react-helmet'
import PageNot from '../ui/images/PageNot'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <>
            <Helmet>
                <title>{"404 Page Not Found"}</title>
                <meta
                    name="description"
                    content="Oops! The page you’re looking for doesn’t exist. It might have been moved or deleted. Navigate back to the homepage or explore other sections of our site."
                />

            </Helmet>
            
            <main className={styles.pagenotfound_wrap}>
                <PageNot/>

                <section>
                    <Link to={"/"}>
                    <button className={styles.back_btn}>Go Back</button>
                    </Link>
                </section>
            </main>
        </>
    )
}

export default PageNotFound
