import React, { useEffect, useState } from "react";
import styles from "./games.module.scss";
import { useNavigate } from "react-router-dom";
import { gamesData, getLobby } from "../../api/api";
import Navbar from "../navbar/Navbar";
import { useSelector, useDispatch } from "../../redux/store";
import { userBalance } from "../../redux/slice/userBalanceSlice";
import Cookies from "js-cookie";
import Loading from "../ui/loading/Loading";
import "react-multi-carousel/lib/styles.css";
import CarouselSlider from "../carosolues/CarouselSlider";
import { Helmet } from "react-helmet";

const Games = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const balanceState = useSelector((state) => state.userReducer);
  const balance =
    balanceState.userBalance.deposit / 100 +
    balanceState.userBalance.withdrawls / 100;
  const formattedBalance = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(balance);

  const navigate = useNavigate();
  const [gamesList, setgamesList] = useState([]);

  useEffect(() => {
    dispatch(userBalance());
  }, [dispatch]);

  const token = Cookies.get("accessToken");

  const fetchGames = async () => {
    try {
      const res = await gamesData({
        token: token,
      });
      console.log(res,"dskdnsooooooooo");
      
      if (res?.status === 200) {
        setLoading(false);
        const allGames = res?.data?.data?.providers.reduce(
          (acc: any[], provider: any) => acc.concat(provider.games),
          []
        );
        console.log(allGames,"dnskdnsduuuuuuuuuu");
        
        setgamesList(allGames);
      }
    } catch (error) {
      console.error("Error creating game:", error);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const handleGLinkClick = async (e: any, gId: any) => {
    e.preventDefault();
    try {
      const response = await getLobby({
        game: gId,
      });
      if (response?.status === 200) {
        navigate(
          `/lobby?gameId=${encodeURIComponent(
            response?.data?.data?.game_launcher
          )}`
        );
      }
    } catch (error) {
      console.error("Error creating game:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Rapid Games"}</title>
        <meta name="description" content="Discover the ultimate online casino experience with our cutting-edge games. Enjoy seamless gameplay, stunning graphics, and secure transactions. Play real-time card games, slots, and live dealer games designed for mobile and desktop. Immerse yourself in interactive gaming, win big, and enjoy premium casino entertainment today!" />
        <meta name="keywords" content="online casino games, best casino platform, real-time casino games, mobile-friendly gambling, interactive slot games, live dealer casino, secure casino gaming, play and win casino, premium casino experience, casino game design, real money betting, seamless casino gameplay, online card games" />
      </Helmet>

      <main className={styles.game_cont}>
        <h1 className={styles.nav_GCont}>
          <Navbar name={"Rapid Games"} balance={formattedBalance} />
        </h1>
        <CarouselSlider />
        {loading ? (
          <Loading />
        ) : (
          <>
            <section className={styles.gameList_cont}>
              {gamesList.filter((items:any)=>items?.image?.url !== "")?.map((g: any, i: any) => {
                return (
                    <div
                      key={i}
                      className={styles.gameImg}
                      onClick={(e) => handleGLinkClick(e, g?.code)}
                    >
                      <img src={g?.image?.url} className={styles.gImg} alt="casino_game_list" />
                    </div>
                  
                );
              })}
            </section>
          </>
        )}
      </main>
    </>
  );
};

export default Games;
