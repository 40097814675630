import React from 'react'

const BackBtn = () => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.292883 7.29289C-0.0976411 7.68342 -0.0976411 8.31658 0.292883 8.70711L6.65685 15.0711C7.04737 15.4616 7.68053 15.4616 8.07106 15.0711C8.46158 14.6805 8.46158 14.0474 8.07106 13.6569L2.4142 8L8.07106 2.34315C8.46158 1.95262 8.46158 1.31946 8.07106 0.928931C7.68053 0.538407 7.04737 0.538407 6.65685 0.928931L0.292883 7.29289ZM1.41443 7H0.99999L0.99999 9H1.41443L1.41443 7Z" fill="black"/>
</svg>
  )
}

export default BackBtn
