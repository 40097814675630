import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

export interface WithdrawInfo {
    withdrawDetails: {
      amount: number;
      bankName: string;
    };
  }
const initialState:WithdrawInfo = {
    withdrawDetails:{
    amount:0,
    bankName:""
    }
    
}

export const withdrawSlice = createSlice({
    name:"withdraw",
    initialState,
    reducers:{
        setWithDrawDetails(
            state: Draft<typeof initialState>,
            action: PayloadAction<typeof initialState.withdrawDetails>
        ) {
            state.withdrawDetails = { ...state.withdrawDetails, ...action.payload };
        }
    }
})


export const {setWithDrawDetails} = withdrawSlice.actions

export default withdrawSlice.reducer



