import React from 'react'

const MyTransactionIcon = () => {
  return (
    <svg width="30" height="28" viewBox="0 0 529 650" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M236.09 10.4015L281.696 102.415H340.905L394.513 0L236.09 10.4015ZM156.088 350.451C219.297 274.442 252.102 310.446 232.899 375.254L217.697 445.666H348.116H356.117L466.533 375.254C484.136 281.64 396.923 121.618 317.711 121.619C232.89 116.817 151.607 246.757 156.088 350.451ZM101.67 445.666L195.284 342.45C205.425 332.582 224.106 319.406 217.697 345.65C211.288 371.894 197.951 437.13 192.083 466.468L356.117 472.869L482.526 396.058C512.93 375.254 541.254 376.215 523.331 412.06C504.929 436.864 430.99 508.714 397.713 530.477C376.91 549.68 350.506 559.601 289.697 564.082C246.758 570.483 157.998 584.725 146.477 590.486C134.955 596.247 135.808 595.02 137.675 593.686L101.67 445.666ZM125.674 629.692L67.2651 415.26L0.855469 438.464L57.6637 649.694L125.674 629.692ZM274.702 209.597L264.893 224.923H292.174C310.872 226.149 320.497 232.954 323.44 241.782H274.702L264.893 257.109H324.666C327.118 270.902 310.872 285.922 292.174 285.922H270.104V301.555L327.756 360.019H361.432L302.007 298.796V297.877C333.886 296.957 353.811 276.42 355.037 257.109H367.273L377.082 241.782H355.037C354.219 237.695 351.542 228.663 347.373 225.23H367.273L377.082 209.597H274.702Z" fill="#111"/>
</svg>
  )
}

export default MyTransactionIcon
