import React from 'react'
import loadingsvg from "../images/1167 (1).gif"

const Loading = () => {
  return (
    <>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"60vh",background:"white"}} >
        <img src={loadingsvg} alt='loading'/>
      </div>
    </>
  )
}

export default Loading
