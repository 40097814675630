import React from 'react'

const RightArrow = () => {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.57969 7.60104C7.91163 7.2691 7.91163 6.7309 7.57969 6.39896L2.17032 0.989591C1.83838 0.657646 1.30018 0.657646 0.968239 0.989591C0.636293 1.32154 0.636293 1.85973 0.968239 2.19167L5.77657 7L0.968239 11.8083C0.636293 12.1403 0.636293 12.6785 0.968239 13.0104C1.30018 13.3424 1.83838 13.3424 2.17032 13.0104L7.57969 7.60104ZM6.56421 7.85H6.97865V6.15H6.56421V7.85Z" fill="black" fill-opacity="0.5"/>
    </svg>
  )
}

export default RightArrow
