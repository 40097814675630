import React from 'react'

const ProfileEditIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12.5" r="12.5" fill="#1D0B3C"/>
    <path d="M16.0822 11.512L13.4847 8.91447L6.82554 15.5736C6.79011 15.609 6.7665 15.7035 6.74289 15.7743L6.04628 18.5962C5.98961 18.8323 6.14073 19.0212 6.40049 18.9504L8.9744 18.3128C9.16332 18.2656 9.32861 18.242 9.43487 18.1475L16.0822 11.512Z" fill="white"/>
    <path d="M16.8142 10.7682L14.2285 8.17063L15.1967 7.21427C15.5273 6.88367 16.3774 6.7066 16.9323 7.21427L17.8414 8.1234C18.0681 8.35954 18.3491 9.1506 17.747 9.87083L16.8142 10.7682Z" fill="white"/>
    </svg>
    
  )
}

export default ProfileEditIcon
