import React from 'react'

const AddBank = () => {
  return (
    <svg width="47" height="39" viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="9.72559" y="24.1016" width="20.6184" height="1.69798" rx="0.848991" fill="black"/>
<rect x="9" y="26.2734" width="21.9567" height="2.19567" rx="1.09783" fill="black"/>
<rect x="10.8979" y="25.2324" width="11.0369" height="2.14269" rx="1.07135" transform="rotate(-90 10.8979 25.2324)" fill="black"/>
<rect x="16.1941" y="25.2324" width="11.2795" height="2.14269" rx="1.07135" transform="rotate(-90 16.1941 25.2324)" fill="black"/>
<rect x="21.4902" y="25.6777" width="11.2795" height="2.14269" rx="1.07135" transform="rotate(-90 21.4902 25.6777)" fill="black"/>
<rect x="26.7458" y="25.2324" width="11.2795" height="2.14269" rx="1.07135" transform="rotate(-90 26.7458 25.2324)" fill="black"/>
<rect x="9.92773" y="13.9141" width="19.8502" height="1.13199" rx="0.565994" fill="black"/>
<path d="M29.006 11.2045L22.3193 8.14774C20.7234 7.4182 18.8907 7.41135 17.2894 8.12892L10.6516 11.1035C9.32141 11.7705 9.96819 12.8217 10.6516 12.9025H28.8443C30.138 12.9632 30.2026 11.629 29.006 11.2045Z" fill="black"/>
<rect x="0.388889" y="0.388889" width="39.6667" height="34.2222" rx="5.83333" stroke="#858585" stroke-width="0.777778" stroke-dasharray="1.56 1.56"/>
<circle cx="39.5" cy="31.5" r="7.5" fill="white"/>
<path d="M47 31.5C47 35.6421 43.6421 39 39.5 39C35.3579 39 32 35.6421 32 31.5C32 27.3579 35.3579 24 39.5 24C43.6421 24 47 27.3579 47 31.5ZM33.1778 31.5C33.1778 34.9917 36.0083 37.8222 39.5 37.8222C42.9917 37.8222 45.8222 34.9917 45.8222 31.5C45.8222 28.0083 42.9917 25.1778 39.5 25.1778C36.0083 25.1778 33.1778 28.0083 33.1778 31.5Z" fill="black"/>
<rect x="38.9233" y="27.4629" width="1.15385" height="8.07692" rx="0.576923" fill="black"/>
<rect x="35.4622" y="32.0762" width="1.15385" height="8.07692" rx="0.576923" transform="rotate(-90 35.4622 32.0762)" fill="black"/>
</svg>
  )
}

export default AddBank
