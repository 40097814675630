import React from 'react'
import styles from "./navbar.module.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import BalanceIcon from '../ui/images/BalanceIcon'
import BackBtn from '../ui/images/BackBtn'


const Navbar = ({ name, balance }: any) => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleBalRouteClick =  () => {
    navigate("/deposite")
  }

  return (
    <div className={styles.nav_cont} style={{ justifyContent: location.pathname === "/" ? "space-between" : "center" }}>
    { location.pathname !== "/" &&  <span className={styles.back_btn} onClick={()=>navigate(-1)}><BackBtn/></span>}
      <header className={styles.header_name}>
        {name}
      </header>
      {
        location.pathname === "/" && <div className={styles.bal}>
          <span className={styles.bal_icon} onClick={handleBalRouteClick}>
            <article>
            Balance
            <p>{balance}</p>
            </article>
            <BalanceIcon width={"26"} heigth={"26"} />
          </span>
          
        </div>
      }

    </div>
  )
}

export default Navbar
