import React, { useState, useRef, useEffect } from 'react';
import styles from './otp.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { postOtp, signUp } from '../../api/api';
import Logo from '../ui/images/Logo';

const Otp = () => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [errMsgres, setErrMsgres] = useState('');
    const [timer, setTimer] = useState(0);
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    useEffect(() => {
        startTimer();
    }, []);

    const startTimer = () => {
        setTimer(30);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) return;
        const updatedOtp = [...otp];
        updatedOtp[index] = value.slice(-1);
        setOtp(updatedOtp);

        if (value && index < 5) {
            inputsRef.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace') {
            const updatedOtp = [...otp];
            updatedOtp[index] = '';
            setOtp(updatedOtp);

            if (index > 0 && otp[index] === '') {
                inputsRef.current[index - 1]?.focus();
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const otpValue = otp.join('');
        if (otpValue.length !== 6) {
            toast.error('Please enter a valid 6-digit OTP.');
            return;
        }

        try {
            const res = await postOtp({
                token: Cookies.get('OtpAccessToken'),
                otp: otpValue,
                userName: Cookies.get('mobile'),
            });
            if (res.status === 200) {
                Cookies.set('setPswToken', res?.data?.data?.accessToken);
                Cookies.set('userId', res?.data?.data?.UserId);
                localStorage.setItem('userId', res?.data?.data?.UserId);
                localStorage.setItem('isRegistered', res?.data?.data?.isRegistired);
                navigate('/setpassword', { replace: true });
            } else if (res?.status === 400) {
                setErrMsgres(res?.data?.message);
            } else {
                console.log('Login unsuccessful');
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message;
            setErrMsgres(errorMessage);
        }
    };

    const handleResendOtp = async () => {
        setTimer(30); // Start a 30-second timer
        try {
            const mobile = Cookies.get('mobile');
            const data =  {
                mobile: mobile || '',
                countryCode: '91',
                type: 'SMS',
                ChannalType: 'ACCOUNT_VERIFICATION',
            }
            await signUp(data);
            toast.success('OTP has been resent successfully.');
        } catch (error) {
            console.error('Error resending OTP:', error);
            toast.error('Failed to resend OTP. Please try again.');
        }
    };

    return (
        <form className={styles.login_main_wrap} onSubmit={handleSubmit}>
            <div className={styles.logn_sub}>
                <div>
                    <Logo />
                </div>
                <h1 className={styles.otp_heading}>Enter the OTP</h1>
                <section className={styles.input_otpbox_wrap}>
                    {otp.map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            className={styles.otpbox}
                            maxLength={1}
                            value={otp[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputsRef.current[index] = el)}
                        />
                    ))}
                </section>
                {errMsgres && <span className={styles.errormsg}>{errMsgres}</span>}

                <button
                    type="button"
                    className={styles.resend_btn}
                    onClick={handleResendOtp}
                    disabled={timer > 0}
                >
                    {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
            </button>
            </div>
            

            <section className={styles.btn_section}>
                <button type="submit" className={styles.lg_btn}>
                    Submit
                </button>
            </section>
        </form>
    );
};

export default Otp;
