import React from 'react'

const NoResult = () => {
  return (
    <>
     <svg width="205" height="183" viewBox="0 0 205 183" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="18.2734" width="204.18" height="68.5321" rx="34.266" fill="#E6E6E6"/>
<rect x="5.94678" y="104.646" width="191.437" height="54.3725" rx="27.1863" fill="#E6E6E6"/>
<rect x="17.2744" y="140.045" width="167.082" height="32.8501" rx="16.425" fill="#E6E6E6"/>
<path d="M106.937 35.3224C107.2 34.7151 106.922 34.0081 106.309 33.7587C100.049 31.2123 93.3534 29.8962 86.5881 29.8868C79.4232 29.8768 72.3319 31.3326 65.7504 34.1647C59.169 36.9967 53.2364 41.1453 48.3176 46.3551C43.6732 51.2742 40.0254 57.0414 37.5708 63.3376C37.3304 63.9543 37.6525 64.6425 38.2744 64.8688C38.8964 65.0951 39.5827 64.7737 39.8238 64.1573C42.1662 58.1682 45.6404 52.682 50.0604 48.0005C54.7548 43.0284 60.4166 39.0692 66.6978 36.3663C72.979 33.6634 79.7467 32.274 86.5848 32.2836C93.0231 32.2926 99.3956 33.5416 105.355 35.9583C105.968 36.207 106.674 35.9296 106.937 35.3224Z" fill="#FDFDFD"/>
<path d="M36.9305 69.1325C36.2912 68.9612 35.6326 69.3403 35.4759 69.9833C34.5519 73.7761 34.0534 77.6602 33.9899 81.5634C33.9791 82.2252 34.5206 82.7583 35.1824 82.754C35.8443 82.7496 36.3758 82.2095 36.3873 81.5478C36.4514 77.8592 36.9224 74.189 37.7917 70.6038C37.9477 69.9606 37.5698 69.3038 36.9305 69.1325Z" fill="#FDFDFD"/>
<circle cx="85.2412" cy="81.144" r="54.8543" stroke="#606060" stroke-width="2.09634"/>
<circle cx="85.2412" cy="81.1445" r="44.4609" stroke="#606060" stroke-width="1.69914"/>
<path d="M124.179 136.93L126.161 134.947L133.1 129.283L135.224 127.443C137.036 126.31 139.047 127.018 140.887 129.567L172.038 166.665C173.624 168.59 173.596 173.178 171.33 175.443L163.967 181.532C160.711 182.665 158.02 181.532 156.18 180.116L123.754 141.602C122.282 139.677 122.622 138.487 124.179 136.93Z" fill="#9E9E9E" stroke="#626262" stroke-width="1.69914"/>
<path d="M125.879 134.949L118.091 125.886L125.029 120.223L132.817 129.285L125.879 134.949Z" stroke="#626262" stroke-width="1.69914"/>
<rect x="138.197" y="138.141" width="3.20361" height="42.7508" rx="1.6018" transform="rotate(-40 138.197 138.141)" fill="#E6E6E6"/>
<path d="M114.211 1.24031L109.844 3.42403C109.554 3.56889 109.445 3.92677 109.604 4.20889L112.147 8.71792C112.301 8.99087 112.648 9.0869 112.92 8.93221L117.173 6.51859C117.445 6.36419 117.54 6.01849 117.386 5.74645L114.957 1.46733C114.808 1.20535 114.481 1.1056 114.211 1.24031Z" stroke="#626262" stroke-width="0.849571"/>
<path d="M44.3857 148.422L38.6355 147.677C38.2035 147.621 37.8726 148.053 38.0396 148.456L40.3823 154.099C40.5539 154.513 41.1147 154.573 41.3703 154.206L44.7778 149.307C45.0204 148.959 44.807 148.477 44.3857 148.422Z" stroke="#626262" stroke-width="0.849571"/>
<path d="M172.746 120.789C177.135 120.789 176.569 127.161 172.746 127.161C169.121 127.161 168.498 120.789 172.746 120.789Z" stroke="#626262" stroke-width="0.849571"/>
<path d="M17.4842 44.3281C14.7941 62.5939 38.2991 93.1784 69.1665 99.4086M69.1665 99.4086C112.212 108.097 112.693 73.1286 102.725 63.7267C78.6537 44.7529 59.3111 78.3393 69.1665 99.4086ZM69.1665 99.4086C85.875 129.144 143.079 99.4086 160.212 70.6648" stroke="#626262" stroke-width="1.41595" stroke-dasharray="3.96 3.96"/>
<circle cx="117.948" cy="156.897" r="2.40712" fill="white"/>
<circle cx="153.206" cy="34.1347" r="1.41595" fill="white"/>
</svg>
 
    </>
  )
}

export default NoResult
