import {
    useDispatch as useDispatchBase,
    useSelector as useSelectorBase,
  } from "react-redux";
  
  import { configureStore } from "@reduxjs/toolkit";
import userBalanceSlice from "./slice/userBalanceSlice";
import  withdrawSlice  from "./slice/WithdrawSlice";
  // import { GameEventSlice } from "./slice/gameEventSlice";
  
  export const store = configureStore({
    reducer: {
      userReducer: userBalanceSlice,
      withdrawReducer:withdrawSlice
    },
  });
  
  export type RootState = ReturnType<typeof store.getState>;
  
  type AppDispatch = typeof store.dispatch;
  
  export const useDispatch = () => useDispatchBase<AppDispatch>();
  
  export const useSelector = <TSelected = unknown>(
    selector: (state: RootState) => TSelected
  ): TSelected => useSelectorBase<RootState, TSelected>(selector);
  